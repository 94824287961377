import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { toolbox } from '../utils/toolbox';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { palette } from '../utils/toolbox/palette';
import texts from '../lang/pages/wip';
import { AppContext } from '../state/app.context';
import ResponsiveAdapter from '../components/utils/ResponsiveAdapter';

const sharedTextStyle = {
  color: toolbox.palette.texts.selected,
};

const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      'html, body': {
        overflow: 'hidden',
      },
    },
    parentContainer: {
      height: '100%',
    },
    childContainer: {
      margin: '2.5em 0',
      '&:first-child': {
        marginTop: '-1em',
      },
    },
    wrapper: {
      paddingLeft: '17%',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '8%',
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 'unset',
      },
    },
    header:  {
      ...sharedTextStyle,
      ...toolbox.fonts.homePage.header,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.9rem',
      },
    },
    title: {
      ...sharedTextStyle,
      ...toolbox.fonts.homePage.title,
      margin: '0.2em 0',
      [theme.breakpoints.down('md')]: {
        fontSize: '2.2rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.6rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.4rem',
      },
    },
    subtitle: {
      ...sharedTextStyle,
      ...toolbox.fonts.homePage.subtitle,
      display: 'inline-block',
      color: toolbox.palette.texts.light,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.9rem',
        display: 'block',
      },
    },
    highlightedText: {
      display: 'inline-block',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      color: toolbox.palette.texts.highlight,
    },
    horizontalLine: {
      width: '35px',
      height: 2,
      marginRight: '0.7em',
      display: 'inline-block',
      border: 'none',
      backgroundColor: toolbox.palette.texts.dark,
    },
    linkContainer: {
      color: palette.links.default,
      '&:hover': {
        color: palette.links.hover,
        cursor: 'pointer',
        transition: 'color .3s',
      },
    },
    iconContainer: {
      border: `1px solid`,
      borderRadius: '100%',
      margin: '0.5em 0.5em 0.5em 0',
      width: 20,
      height: 20,
      [theme.breakpoints.down('xs')]: {
        width: 15,
        height: 15,
      },
    },
    arrowIcon: {
      width: '85%',
      height: 'auto',
    },
    link: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.8em',
      },
    },
  });

const WorkInProgress: React.FunctionComponent<WithStyles> = (props: React.PropsWithChildren<WithStyles>) => {
  const { classes } = props;
  const { language } = useContext(AppContext);
  const T = texts.current(language);

  return (
    <ResponsiveAdapter
      desktop={
        <Grid container direction='column' justify='center' alignItems='flex-start' className={classes.parentContainer} />
      } mobile={
        <Grid container direction='column' justify='center' alignItems='center' className={classes.parentContainer} />
      }>
        <div className={classes.wrapper}>
          <Grid item className={classes.childContainer}>
            <Typography className={classes.header}>
              {T.header1} <Typography className={classes.highlightedText}>{T.header2}</Typography>.
            </Typography>
            <Typography className={classes.title}>
              {T.title1} <Typography className={classes.highlightedText}>{T.title2}</Typography> {T.title3}
            </Typography>
            <hr className={classes.horizontalLine}/>
            <Typography className={classes.subtitle}>
            {T.subtitle}
            </Typography>
          </Grid>

          <Grid item className={classes.childContainer}>
            <a href='https://www.linkedin.com/in/julien-souki-l%C3%A9on-91a1a8134/' target='_blank' rel="noreferrer">
              <Grid container direction='row' alignItems='center' className={classes.linkContainer}>
                <Grid className={classes.iconContainer} container direction='column' justify='center' alignItems='center'>
                  <ArrowForwardIcon className={classes.arrowIcon} />
                </Grid>
                <Typography className={classes.link}>{T.link1}</Typography>
              </Grid>
            </a>

            <a href='mailto: julien.soukileon@gmail.com' target='_blank' rel="noreferrer">
              <Grid container direction='row' alignItems='center' className={classes.linkContainer}>
                <Grid className={classes.iconContainer} container direction='column' justify='center' alignItems='center'>
                  <ArrowForwardIcon className={classes.arrowIcon} />
                </Grid>
                <Typography className={classes.link}>{T.link2}</Typography>
              </Grid>
            </a>
          </Grid>
        </div>
    </ResponsiveAdapter>
  );
};

export default withStyles(styles)(WorkInProgress);
