export default {
  travel: {
    title: "Voyager",
    description: "Mes parent m'ont transmis la passion du voyage. En étant jeune j'ai eu la chance de partir au Canada et dans l'Ouest Américain. Depuis je voyage dès que j'en ai l'occasion.",
  },
  dogs: {
    title: "Les chiens",
    description: "J'aime les animaux et en particulier les chiens pour la joie de vivre qu'ils apportent.",
  },
  piano: {
    title: "Piano",
    description: "J'ai pris des cours de piano pendant six ans pendant mes études, et je continue d'en jouer régulièrement.",
  },
  soccer: {
    title: "Football",
    description: "Je suis fan de football et en particulier de l'équipe de France de football, dont je ne rate pas un matc!",
  },
  photo: {
    title: "Photographie",
    description: "J'aime prendre des photos, juste pour le fun. Je ne me débrouille pas si mal!",
  },
};
