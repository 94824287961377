import { Languages } from "../../config/languages";

export const getLanguageFromURL = (url: string): Languages => {
  const languageInURL = url
    .split('/')
    .find(str => (Object.values(Languages) as ('en' | 'fr')[])
    .find(lang => lang === str ));
  const languagesByEnumKey: { [key: string]: Languages } = {
    ['en']: Languages.ENGLISH,
    ['fr']: Languages.FRENCH,
  };
  return languageInURL ? languagesByEnumKey[languageInURL] : Languages.ENGLISH;
};
