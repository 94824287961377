import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography, Divider } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { palette } from '../utils/toolbox/palette';
import { projects } from '../data/projects';
import { useHistory } from "react-router-dom";
import { times } from 'lodash';
import AutocompleteInput from '../components/form-controls/AutocompleteInput';
import { skills } from '../data/skills';
import ResponsiveAdapter from '../components/utils/ResponsiveAdapter';
import Select from '../components/form-controls/Select';
import { Project, ProjectCategory, ProjectType } from '../interfaces/interfaces';
import { searchProjectsByCategory } from '../utils/search/search-utils';
import { AppContext } from '../state/app.context';
import { translateCategory } from '../lang/utils/categories';
import texts from '../lang/pages/my-projects';
import { useSpring, animated } from 'react-spring';
import { get } from 'lodash-es';
import queryString from 'query-string';
import { URLEncode } from '../utils/URL/url-utils';
import { translateTag } from '../lang/utils/tags';

const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '::-webkit-scrollbar': {
        width: 0,
        height: 0,
        background: 'transparent',
      },
    },
    root: {
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    arrow: {
      color: palette.texts.light,
      transition: 'color .5s',
      '&:hover': {
        cursor: 'pointer',
        color: '#fff',
      },
    },
    categoryContainer: {
      color: palette.texts.light,
      marginBottom: theme.spacing(0.5),
    },
    category: {
      margin: theme.spacing(1) + 1,
      padding: '3px 8px',
      textTransform: 'capitalize',

      '&:hover': {
        cursor: 'pointer',
        transition: 'color .3s',
        color: '#fff',
      },
    },
    activeCategory: {
      margin: theme.spacing(1),
      color: palette.texts.highlight,
      border: `1px solid ${palette.texts.highlight}`,
      '&:hover': {
        color: palette.texts.highlight,
      },
    },
    divider: {
      backgroundColor: palette.texts.light,
      width: '100%',
    },
    filtersContainer: {
      color: palette.texts.light,
      paddingTop: theme.spacing(2),
    },
    filtersInput: {
      marginLeft: theme.spacing(1),
      border: 'none',
      width: '100%',
      backgroundColor: 'transparent',
      color: "#fff",
      fontFamily: 'Roboto',
      fontSize: 14,
      '&::placeholder': {
        color: palette.texts.light,
        fontStyle: 'italic',
      },
      '&:focus': {
        outlineWidth: 0,
      },
    },
    projectContainer: {
      position: 'relative',
      width: 250,
      height: 450,
      backgroundColor: palette.texts.light,
      flexShrink: 0,
      margin: theme.spacing(1),
      transition: 'all .5s',
      zIndex: 1,
      [theme.breakpoints.up('sm')]: {
        '&:hover $projectPreviewContainer': {
          opacity: 1,
        },
        '&:hover $projectLogo': {
          opacity: 0,
        },
        '&:hover': {
          cursor: 'pointer',
          transform: 'scaleY(1.15) scaleX(1.05)',
          zIndex: 2,
        },
      },
      [theme.breakpoints.down(1466)]: {
        width: 188,
        height: 336,  
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 100,
        borderRadius: 7,
        margin: 0,
        marginTop: theme.spacing(2),
      },
    },
    bottomContainer: {
      width: '100%',
    },
    projectsContainer: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      flexWrap: 'nowrap',
      overflowX: 'auto',
      flexShrink: 0,
      scrollBehavior: 'smooth',
      [theme.breakpoints.down('xs')]: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        height: '100%',
        paddingTop: 'unset',
        paddingBottom: 'unset',
      },
    },
    projectLogo: {
      width: '90%',
      transition: 'all .5s',
      position: 'absolute',
      top: '50%', 
      right: '50%',
      transform: 'translate(50%,-50%)',
      [theme.breakpoints.down('xs')]: {
        width: '60%',
      },
    },
    projectPreviewContainer: {
      position: 'absolute',
      bottom: 0,
      padding: theme.spacing(1),
      opacity: 0,
    },
    tag: {
      padding: '3px 10px',
      fontSize: 13,
      backgroundColor: '#444444',
      color: "#fff",
      borderRadius: 100,
      margin: theme.spacing(0.3),
      '&:firstChild': {
        marginLeft: 0,
      },
      '&:lastChild': {
        marginRight: 0,
      },
    },
    projectName: {
      fontSize: 16,
      color: '#fff',
      fontWeight: 'bold',
      marginLeft: 5,
      marginBottom: 5,
    },
    previewImage: {
      width: '100%',
      maxHeight: '100%',
      transition: 'background-color 0.3s ease-out 0s',
    },
    active: {
      transition: 'all 0.2s ease-in .3s',
    },
    selectLabel: {
      color: '#fff',
      marginBottom: theme.spacing(1),
    },
    selectContainer: {
      marginBottom: theme.spacing(3),
    },
  });

const categories: (ProjectCategory)[] = ['all', ProjectType.JOB, ProjectType.PERSO, ProjectType.SCHOOL];

const MyProjects: React.FunctionComponent<WithStyles> = (props: React.PropsWithChildren<WithStyles>) => {
  const { language } = useContext(AppContext);
  const { classes } = props;
  const previewImagesRefs = times(projects.length, () => useRef(null)); 
  const projecsContainerRef = useRef(null);
  const [showPreview, setShowPreview] = useState<boolean[]>([]);
  const [activeCategory, setActiveCategory] = useState<ProjectCategory>('all');
  const [filters, setFilters] = useState<{ label: string; value: string }[]>([]);
  const [matchingProjects, setMatchingProjects] = useState<Project[]>([]);
  const T = texts.current(language);
  const history = useHistory();

  const skillsOptions = useMemo(
    () => skills.map(skill => ({
      label: translateTag(skill.name, language),
      value: translateTag(skill.name, language),
    })), [skills],
  );
  const categoriesOptions = useMemo(() => categories.map(category =>
    ({ 
      label: translateCategory(category, language),
      value: category,
    })), [categories, language]);

  const [_projectsAnimation, api] = useSpring(() => ({
    from: { opacity: 0, y: -20 }, 
    to: { opacity: 1, y: 0 },
    config: { duration: 400 },
  }));

  const categoriesAnimation = categories.map((_, index) => useSpring({
    from: { opacity: 0, y: 20 }, 
    to: { opacity: 1, y: 0 },
    config: { duration: 400 },
    delay: index * 100,
  }));

  const filtersAnimation = useSpring({
    from: { opacity: 0, y: -20 }, 
    to: { opacity: 1, y: 0 },
    config: { duration: 400 },
  });

  const dividerAnimation = useSpring({
    from: { opacity: 0, width: '0%' }, 
    to: { opacity: 1, width: '100%' },
    config: { duration: 400 },
  });

  useEffect(() => {
    const queryParams = get(window, 'location.search', '');
    const queryParamsParsed = queryString.parse(queryParams);  
    const queryFilters = typeof queryParamsParsed.filter === "string"
      ? [queryParamsParsed.filter]
      : queryParamsParsed.filter;

    if (queryFilters) {
      const skillsNames = skills.map(skill => skill.name);
      setFilters(
        queryFilters.filter(f => skillsNames
          .map(skill => translateTag(skill, language))
          .includes(f),
        ).map(f => ({ label: f, value: f })),
      );
    }
  }, []);

  useEffect(() => {
    const projectsInCategory =
      searchProjectsByCategory(
        projects.filter(project => project.type !== ProjectType.OTHER),
        activeCategory,
        filters.map(filter => filter.value),
        language,
      );
    setMatchingProjects(projectsInCategory);
    api.start({
      from: { opacity: 0, y: -20 }, 
      to: { opacity: 1, y: 0 },
      config: { duration: 400 },
    });
  }, [filters, activeCategory]);

  const updateArray = (array: any[], index: number, value: any): any[] => {
    const newArray = showPreview;
    array[index] = value;
    return newArray;
  }

  const loadPreview = (index: number) => {
    setShowPreview([...updateArray(showPreview, index, true)]);
  };

  const hidePreview = (index: number) => {
    setShowPreview([...updateArray(showPreview, index, false)]);
  };

  const scroll = (direction: 1 | -1) => {
    if (projecsContainerRef.current) {
      (projecsContainerRef.current as any).scrollLeft += 266 * direction;
    }
  };

  const handleProjectClick = (projectName: string) => {
    const URLProjectName = URLEncode(projectName);
    history.push(`/${language}/project/${URLProjectName}${window.location.search}`);
  };

  return (
    <Grid container style={{ height: '100%'}} alignItems='center' justify='center'>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container direction="column">
          <ResponsiveAdapter
            desktop={
              <>
                <Grid container direction="row" className={classes.categoryContainer}>
                  {categories.map((category, index) => (
                    <animated.div style={categoriesAnimation[index]} key={`animation-${category}-${index}`}>
                      <Typography
                        key={category}
                        style={{ marginLeft: category === 'all' ? category === activeCategory ? 0 : 1 : undefined}}
                        className={`${classes.category} ${category === activeCategory ? classes.activeCategory : ''}`}
                        onClick={() => setActiveCategory(category)}
                      >
                        {translateCategory(category, language, true)}
                      </Typography>
                    </animated.div>
                  ))} 
                </Grid>  
                <animated.div style={dividerAnimation}>
                  <Divider className={classes.divider} />
                </animated.div>
                <animated.div style={filtersAnimation}>
                  <Grid item container direction="row" className={classes.filtersContainer}>
                    <Typography>{T.filters} : </Typography>
                    <Grid item style={{ flexGrow: 1, position: 'relative', top: -3, marginLeft: 8 }}>
                      <AutocompleteInput
                        options={skillsOptions}
                        placeholder={T.filtersPlaceholder}
                        value={filters}
                        setValue={setFilters}
                      ></AutocompleteInput>
                    </Grid>
                  </Grid>
                </animated.div>
              </>
            } mobile={
              <Grid container direction='column' className={classes.selectContainer}>
                <Typography className={classes.selectLabel}>{T.typeOfProjects}</Typography>
                <Select
                  options={categoriesOptions} 
                  value={activeCategory}
                  setValue={setActiveCategory as (value: string) => void}
                />
              </Grid>
            } 
          />
        </Grid>
        <animated.div>
          <Grid container item direction="row" className={classes.bottomContainer}>
            <ResponsiveAdapter
              desktop={
                <Grid container item direction="column" alignItems="center" justify="center" xs={1}>
                  <ArrowForwardIcon
                    className={classes.arrow}
                    style={{ transform: 'rotate(180deg)' }}
                    onClick={() => scroll(-1)}
                  />
                </Grid>
              } mobile={
                <></>
              }
            />
            <ResponsiveAdapter
              desktop={
                <Grid container item direction="row" className={classes.projectsContainer} xs={10} ref={projecsContainerRef} />
              } mobile={
                <Grid container item direction="column" className={classes.projectsContainer} xs={12} ref={projecsContainerRef} />
              }
            >
              {matchingProjects.map((project, index) => (
                <Grid
                  container
                  item
                  key={project.name}
                  className={classes.projectContainer}
                  style={{ background: project.projectsPage.backgroundColor }}
                  alignItems='center'
                  justify='center'
                  onClick={() => handleProjectClick(project.name)}
                  onMouseEnter={() => loadPreview(index)}
                  onMouseLeave={() => hidePreview(index)}
                >
                  <ResponsiveAdapter
                    desktop={
                      <img
                        src={project.projectsPage.logo}
                        className={classes.projectLogo}
                        style={{ width: project.projectsPage.overrideWidth }}
                      />
                    } mobile={
                      <img
                        src={project.projectsPage.logo}
                        className={classes.projectLogo}
                        style={{ width: project.projectsPage.overrideWidthMobile }}
                      />
                    }
                  />
                  <ResponsiveAdapter
                    desktop={
                      <img
                      src={project.projectsPage.hoverImage}
                      ref={previewImagesRefs[index]}
                      className={`${classes.previewImage} ${showPreview[index] ? classes.active : ''}`}
                      style={{
                        opacity: showPreview[index] ? 1 : 0,
                      }}
                    />
                    } mobile={
                      <></>
                    }
                  />
                  <Grid
                    container
                    direction='column'
                    className={`${classes.projectPreviewContainer} ${showPreview[index] ? classes.active : ''}`}
                  >
                    <Typography className={classes.projectName}>{project.name}</Typography>
                    <Grid container direction="row">
                      {project.tags.map((tag, tIndex) => (
                        tIndex < 3
                          ? <Typography key={tag} className={classes.tag}>
                            {translateTag(tag, language)}
                          </Typography>
                          : <></>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              {matchingProjects.length === 0 && (
                <ResponsiveAdapter desktop={
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ 
                      height: 466, 
                      color: '#fff',
                    }}
                  />
                } mobile={
                  <Grid
                    direction="column"
                    container
                    alignItems="center"
                    justify="center"
                    style={{ 
                      color: '#fff',
                    }}
                  />
                }
              >
                <Typography>{T.noResult}</Typography>
              </ResponsiveAdapter>
              )}
            </ResponsiveAdapter>
            <ResponsiveAdapter
              desktop={
                <Grid container item direction="column" alignItems="center" justify="center" xs={1}>
                  <ArrowForwardIcon className={classes.arrow} onClick={() => scroll(1)} />
                </Grid>  
              } mobile={
                <></>
              }
            />
          </Grid>
        </animated.div>
      </Container>
    </Grid>
  );
};

export default withStyles(styles)(MyProjects);
