import React, { useContext } from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { toolbox } from '../utils/toolbox';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { palette } from '../utils/toolbox/palette';
import texts from '../lang/pages/home';
import { AppContext } from '../state/app.context';
import ResponsiveAdapter from '../components/utils/ResponsiveAdapter';
import { useHistory } from 'react-router';
import { animated, useSpring } from 'react-spring';
import ImageLoader from '../components/utils/image-loader';
import HomeImage from '../assets/home.jpg';

const sharedTextStyle = {
  color: toolbox.palette.texts.selected,
};

const TABLET_WIDTH = 1025;
const MOBILE_WIDTH = 775;

const styles = (theme: Theme) =>
  createStyles({
    parentContainer: {
      height: '100%',
    },
    childContainer: {
      margin: '2.5em 0',
      '&:first-child': {
        [theme.breakpoints.down(MOBILE_WIDTH)]: {
          textAlign: 'center',
        },
      },
    },
    muiContainer: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    mainContainer: {
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        flexDirection: 'column-reverse',
      },
    },
    header:  {
      ...sharedTextStyle,
      ...toolbox.fonts.homePage.header,
      [theme.breakpoints.down(1466)]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down(TABLET_WIDTH)]: {
        fontSize: '1.1rem',
      },
    },
    title: {
      ...sharedTextStyle,
      ...toolbox.fonts.homePage.title,
      margin: '0.2em 0',
      [theme.breakpoints.down(1466)]: {
        fontSize: '2.2rem',
      },
      [theme.breakpoints.down(TABLET_WIDTH)]: {
        fontSize: '1.6rem',
      },
    },
    subtitle: {
      ...sharedTextStyle,
      ...toolbox.fonts.homePage.subtitle,
      display: 'inline-block',
      color: toolbox.palette.texts.light,
      [theme.breakpoints.down(1466)]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down(TABLET_WIDTH)]: {
        fontSize: '1.1rem',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    highlightedText: {
      display: 'inline-block',
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    horizontalLine: {
      width: '35px',
      height: 2,
      margin: 0,
      marginRight: '0.7em',
      display: 'inline-block',
      border: 'none',
      backgroundColor: toolbox.palette.texts.dark,
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        display: 'none',
      },
    },
    linkContainer: {
      color: palette.links.default,
      '&:hover': {
        color: palette.links.hover,
        cursor: 'pointer',
        transition: 'color .3s',
      },
    },
    iconContainer: {
      border: `1px solid`,
      borderRadius: '100%',
      margin: '0.5em 0.5em 0.5em 0',
      width: 20,
      height: 20,
      [theme.breakpoints.down('xs')]: {
        width: 15,
        height: 15,
      },
    },
    arrowIcon: {
      width: '85%',
      height: 'auto',
    },
    link: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1em',
      },
    },
    imageWrapper: {
      position: 'relative',
      [theme.breakpoints.down(1466)]: {
        width: 310,
        height: 310,  
      },
      [theme.breakpoints.down(TABLET_WIDTH)]: {
        width: 250,
        height: 250,  
      },
      [theme.breakpoints.down('xs')]: {
        width: 180,
        height: 180,
      },
      width: 330,
      height: 330,
    },
    imageContainer: {
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.up(MOBILE_WIDTH)]: {
        '&:hover $imageBackground': {
          backgroundColor: palette.texts.highlight,
          left: 6,
          top: 6,
        },
      },
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        maxWidth: '100%',
        flexBasis: '100%',
      },
      [theme.breakpoints.up(TABLET_WIDTH)]: {
        '&:hover $imageBackground': {
          left: 10,
          top: 10,
        },
      },
    },
    imageBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 15,
      top: 15,
      transition: 'background-color .2s, all .4s',
      backgroundColor: palette.texts.dark,
      [theme.breakpoints.down(TABLET_WIDTH)]: {
        left: 10,
        top: 10,  
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    image: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 2,
      backgroundColor: palette.texts.selected,
    },
    textContainer: {
      width: 'auto',
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        maxWidth: '100%',
        flexBasis: '100%',
        alignItems: 'center',
      },
    },
    hrContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        justifyContent: 'center',
      },
    },
  });

const Home: React.FunctionComponent<WithStyles> = (props: React.PropsWithChildren<WithStyles>) => {
  const { classes } = props;
  const { language } = useContext(AppContext);
  const T = texts.current(language);
  const history = useHistory();

  const arrowAnimation = (order: number) => useSpring({
    from: { opacity: 0, x: -10 }, 
    to: { opacity: 1, x: 0 },
    config: { duration: 200 },
    delay: 600 + 150 * order,
  });

  const subtitleAnimation = useSpring({
    from: { opacity: 0, y: -15 }, 
    to: { opacity: 1, y: 0 },
    config: { duration: 200 },
    delay: 600,
  });

  const textColorAnimation = useSpring({
    from: { color: '#fff' }, 
    to: { color: palette.texts.highlight },
    config: { duration: 200 },
    delay: 600,
  });

  const redirectToPage = (page: string) => {
    history.push(`/${language}/${page}${window.location.search}`);
  };

  return (
    <ResponsiveAdapter
      desktop={
        <Grid container direction='column' justify='center' alignItems='flex-start' className={classes.parentContainer} />
      } mobile={
        <Grid container direction='column' justify='center' alignItems='center' className={classes.parentContainer} />
      }
    >
      <Container maxWidth="lg" className={classes.muiContainer}>
        <Grid container direction='row' alignItems='center' justify='space-evenly' className={classes.mainContainer}>
          <Grid item container direction='column' justify='center' className={classes.textContainer}>
              <Grid item className={classes.childContainer}>
                <Typography className={classes.header}>
                  {T.header},
                </Typography>
                <Typography className={classes.title}>
                  {T.title1} <Typography className={classes.highlightedText}>
                    <animated.div style={textColorAnimation}>{T.title2}</animated.div>
                    </Typography>.
                </Typography>
                <animated.div style={subtitleAnimation} className={classes.hrContainer}>
                  <hr className={classes.horizontalLine}/>
                  <Typography className={classes.subtitle}>
                    {T.subtitle}
                  </Typography>
                </animated.div>
              </Grid>
            <Grid item className={classes.childContainer}>
              <animated.div style={arrowAnimation(0)}>
                <Grid container direction='row' alignItems='center' className={classes.linkContainer}>
                  <Grid className={classes.iconContainer} container direction='column' justify='center' alignItems='center'>
                    <ArrowForwardIcon className={classes.arrowIcon} />
                  </Grid>
                  <Typography onClick={() => redirectToPage('about-me/skills')} className={classes.link}>{T.link1}</Typography>
                </Grid>
              </animated.div>

              <animated.div style={arrowAnimation(1)}>
                <Grid container direction='row' alignItems='center' className={classes.linkContainer}>
                  <Grid className={classes.iconContainer} container direction='column' justify='center' alignItems='center'>
                    <ArrowForwardIcon className={classes.arrowIcon} />
                  </Grid>
                  <Typography onClick={() => redirectToPage('contact')} className={classes.link}>{T.link2}</Typography>
                </Grid>
              </animated.div>
            </Grid>
          </Grid>
          <Grid container direction='row' justify='center' item xs={5} className={classes.imageContainer}>
            <div className={classes.imageWrapper}>
              <div className={classes.imageBackground}></div>
              <ImageLoader
                src={HomeImage}
                className={classes.image}
                alt='Julien Souki-Léon, a fullstack software engineer.'
                title='Fullstack software engineer' 
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </ResponsiveAdapter>
  );
};

export default withStyles(styles)(Home);
