import { Tag, Skill } from '../interfaces/interfaces';
import ReactLogo from '../assets/react_logo.png';
import ReactNativeLogo from '../assets/react_native_logo.png';
import NodeJsLogo from '../assets/node_js_logo.png';
import AWSLogo from '../assets/aws_logo.png';
import mongoDBLogo from '../assets/mongo_logo.webp';
import SQLLogo from '../assets/sql_logo.png';
import typescriptLogo from '../assets/typescript_logo.png';
import javascriptLogo from '../assets/javascript_logo.png';
import CSSLogo from '../assets/css_logo.png';
import PHPLogo from '../assets/php_logo.png';
import HTMLLogo from '../assets/html_logo.png';
import pythonLogo from '../assets/python_logo.png';
import gitLogo from '../assets/git_logo.png';
import dotNetLogo from '../assets/dotnet_logo.png';

/* HARD SKILLS */

const react: Skill = {
  name: Tag.REACT,
  type: 'hard',
  isFav: true,
  image: ReactLogo,
  overrideStyle: {
    width: 70,
    sWidth: 70,
    xsWidth: 70,
  },
};

const nodejs: Skill = {
  name: Tag.NODEJS,
  type: 'hard',
  isFav: true,
  image: NodeJsLogo,
  overrideStyle: {
    width: 50,
    sWidth: 60,
    xsWidth: 50,
  },
};

const AWS: Skill = {
  name: Tag.AWS,
  type: 'hard',
  isFav: false,
  image: AWSLogo,
  overrideStyle: {
    width: 50,
    sWidth: 60,
    xsWidth: 50,
  },
};

const mongoDB: Skill = {
  name: Tag.MONGODB,
  type: 'hard',
  isFav: false,
  image: mongoDBLogo,
  overrideStyle: {
    width: 50,
    sWidth: 50,
    xsWidth: 50,
  },
};

const SQL: Skill = {
  name: Tag.SQL,
  type: 'hard',
  isFav: false,
  image: SQLLogo,
  overrideStyle: {
    width: 50,
    sWidth: 50,
    xsWidth: 50,
  },
};

const typescript: Skill = {
  name: Tag.TYPESCRIPT,
  type: 'hard',
  isFav: true,
  image: typescriptLogo,
  overrideStyle: {
    top: -4,
    width: 45,
    sWidth: 45,
    xsWidth: 45,
  },
};

const JS: Skill = {
  name: Tag.JAVASCRIPT,
  type: 'hard',
  isFav: true,
  image: javascriptLogo,
  overrideStyle: {
    top: -4,
    width: 45,
    sWidth: 45,
    xsWidth: 45,
  },
};

const CSS: Skill = {
  name: Tag.CSS,
  type: 'hard',
  isFav: false,
  image: CSSLogo,
  overrideStyle: {
    width: 50,
    sWidth: 50,
    xsWidth: 50,
  },
};

const PHP: Skill = {
  name: Tag.PHP,
  type: 'hard',
  isFav: false,
  image: PHPLogo,
  overrideStyle: {
    top: -8,
    width: 65,
    sWidth: 70,
    xsWidth: 60,
  },
};

const python: Skill = {
  name: Tag.PYTHON,
  type: 'hard',
  isFav: false,
  image: pythonLogo,
  overrideStyle: {
    width: 50,
    sWidth: 50,
    xsWidth: 50,
  },
};

const HTML: Skill = {
  name: Tag.HTML,
  type: 'hard',
  isFav: false,
  image: HTMLLogo,
  overrideStyle: {
    width: 50,
    sWidth: 50,
    xsWidth: 50,
  },
};

const dotNet: Skill = {
  name: Tag.DOT_NET,
  type: 'hard',
  isFav: false,
  image: dotNetLogo,
  overrideStyle: {
    top: -8,
  },
};

const reactNative: Skill = {
  name: Tag.REACT_NATIVE,
  type: 'hard',
  isFav: false,
  image: ReactNativeLogo,
  overrideStyle: {
    width: 70,
    sWidth: 70,
    xsWidth: 70,
  },
};

const git: Skill = {
  name: Tag.GIT,
  type: 'hard',
  isFav: false,
  image: gitLogo,
  overrideStyle: {
    width: 50,
    sWidth: 50,
    xsWidth: 50,
  },
};

const MSSQL: Skill = {
  name: Tag.MSSQL,
  type: 'hard',
  isFav: false,
  excludeOnMySkills: true,
  image: '',
};

const MYSQL: Skill = {
  name: Tag.MYSQL,
  type: 'hard',
  isFav: false,
  excludeOnMySkills: true,
  image: '',
};

const WP: Skill = {
  name: Tag.WP,
  type: 'hard',
  isFav: false,
  excludeOnMySkills: true,
  image: '',
};

const design: Skill = {
  name: Tag.DESIGN,
  type: 'hard',
  isFav: false,
  excludeOnMySkills: true,
  image: '',
};

const java: Skill = {
  name: Tag.JAVA,
  type: 'hard',
  isFav: false,
  excludeOnMySkills: true,
  image: '',
};

const cpp: Skill = {
  name: Tag.CPP,
  type: 'hard',
  isFav: false,
  excludeOnMySkills: true,
  image: '',
};

const vb: Skill = {
  name: Tag.VB,
  type: 'hard',
  isFav: false,
  excludeOnMySkills: true,
  image: '',
};

export const skills = [
  react,
  nodejs,
  JS,
  typescript,
  git,
  HTML,
  CSS,
  reactNative,
  dotNet,
  PHP,
  SQL,
  AWS,
  mongoDB,
  python,
  MSSQL,
  MYSQL,
  WP,
  design,
  java,
  cpp,
  vb,
];