export default {
  description: "Une question? Envie de discuter? N'hésitez pas à m'envoyer un message et je vous répondrai dès que possible!",
  labels: {
    name: "Votre nom",
    email: "Votre email",
    message: "Votre message",
  },
  failureMessage: "Votre message n'a pas pu être envoyé... 😢",
  successMessage: "Votre message a bien été envoyé, je vous répondrai dès que possible 😁",
  formButton: "Envoyer",
}