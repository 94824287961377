import React, { useContext } from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import { Project } from '../../../interfaces/interfaces';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { URLEncode } from '../../../utils/URL/url-utils';
import { useHistory } from 'react-router';
import { translateCategory } from '../../../lang/utils/categories';
import { AppContext } from '../../../state/app.context';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#303030',
      transition: 'background-color .5s',
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1.3),
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#282828',
      },
    },
    imageContainer: {
      height: 40,
      width: 40,
    },
    thumbnail: {
      width: '100%',
      height: '100%',
    },
    textContainer: {
      width: 'auto',
      paddingLeft: theme.spacing(1.5),
    },
    title: {
      color: '#fff',
      fontSize: 14,
    },
    subtitle: {
      color: '#6B6B6B',
      fontSize: 13,
      textTransform: 'capitalize',
    },
  });

interface Props extends WithStyles {
  project: Project;
  index: number;
  closeMenu: () => void;
}

const SearchItem: React.FunctionComponent<Props> = (props: Props) => {
  const { language } = useContext(AppContext);
  const { classes, project, closeMenu, index } = props;
  const history = useHistory();

  const handleProjectClick = (): void => {
    const projectName = URLEncode(project.name);
    history.push(`/${language}/project/${projectName}${window.location.search}`);
    closeMenu();
  };

  const appearanceAnimation = useSpring({
    from: { opacity: 0, x: -20 }, 
    to: { opacity: 1, x: 0 },
    config: { duration: 300 },
    delay: 100 * index,
  });

  return (
    <animated.div style={appearanceAnimation}>
      <Paper className={classes.root} onClick={handleProjectClick}>
        <Grid container direction='row'>
          <div className={classes.imageContainer}>
            <img className={classes.thumbnail} src={project.thumbnail} />
          </div>
          <Grid container direction='column' justify='center' className={classes.textContainer}>
            <Typography className={classes.title}>{project.name}</Typography>
            <Typography className={classes.subtitle}>{translateCategory(project.type, language)}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </animated.div>
  );
};

export default withStyles(styles)(SearchItem);
