export default {
  travel: {
    title: "travel",
    description: "When I was young I had the chance to go to Canada and the American West Coast with my parents. Since then I travel whenever I have the opportunity.",
  },
  dogs: {
    title: "dogs",
    description: "I love animals and especially dogs for the joy of life they bring.",
  },
  piano: {
    title: "Piano",
    description: "I took piano lessons for six years during my studies, and I still play it regularly.",
  },
  soccer: {
    title: "Soccer",
    description: "I am a fan of soccer and in particular of the French national team. I never miss their games!",
  },
  photo: {
    title: "Photography",
    description: "I like to take pictures, just for fun. I'm not doing so bad!",
  },
};
