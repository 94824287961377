import fr from './fr';
import en from './en';
import { Languages, LanguagesManager } from '../../../../config/languages';

export interface Definition {
  travel: {
    title: string;
    description: string;
  };
  dogs: {
    title: string;
    description: string;
  };
  piano: {
    title: string;
    description: string;
  };
  soccer: {
    title: string;
    description: string;
  };
  photo: {
    title: string;
    description: string;
  };
}

export default new LanguagesManager<Definition>({
  [Languages.FRENCH]: fr,
  [Languages.ENGLISH]: en,
});
