import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { get } from 'lodash';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { menuItems } from '../../../utils/menu/menu-items';
import { MenuItem } from '../../../interfaces/interfaces';
import { AppContext } from '../../../state/app.context';
import { isHome } from '../../../utils/URL/url-utils';

const styles = () =>
  createStyles({
    '@global': {
      'a': {
        color: 'unset',
      },
    },
    menuItem: {
      padding: 12,
      textTransform: 'capitalize',
      borderRadius: 8,
      '&:first-child': {
        marginTop: '1em',
      },
      '&:hover > p': {
        color: '#fff',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    menuItemIcon: {
      color: '#fff',
      marginRight: '1.5em',
      fontSize: 20,
    },
    menuItemText: {
      fontSize: 13,
    },
  });

const getMenuLevel = (item: MenuItem): number => {
  return menuItems.find(i => item === i) ? 1 : 2; 
};  

interface Props extends WithStyles {
  item: MenuItem;
  closeMenu: () => void;
}

const UIMenuItem: React.FunctionComponent<Props> = (props: Props) => {
  const { classes, item, closeMenu } = props;
  const { language } = useContext(AppContext);

  const Icon = item.icon;
  const level = getMenuLevel(item);
  const isFirstItem = menuItems[0] === item;
  const queryParams = get(window, 'location.search', '');

  return (
    <NavLink
      to={{
        pathname: item.items ? `/${language}/${item.items[0].url}` : `/${language}/${item.url}`, 
        search: queryParams,
      }}
      onClick={closeMenu}
      style={{ color: '#656565' }}
      isActive={(_match, location) => {
        if (level === 1 && (item.url === '' && (isHome(location.pathname, language))) || (item.url !== '' && location.pathname.includes(item.url))) {
          return true;
        } else if (level === 2 && location.pathname.includes(item.url)) {
          return true;
        } else if (level === 1 && item.url.includes('project') && location.pathname.includes('project')) {
          return true;
        }
        return false;
      }}
      activeStyle={{
        color: '#fff',
        backgroundColor: '#363636',
      }}
    >
      <Grid
        container
        direction="row"
        key={item.title[language]}
        style={{
          paddingLeft: level === 2 ? '2em' : undefined,
          marginTop: isFirstItem ? '1em' : 10,
          backgroundColor: level === 1 ? 'inherit' : 'unset',
          color: 'inherit',
        }}
        className={`${classes.menuItem}`}
      >
        <Icon className={classes.menuItemIcon} />
        <Typography className={classes.menuItemText}>
          {item.title[language]}
        </Typography>
      </Grid>
    </NavLink>
  );
};

export default withStyles(styles)(UIMenuItem);
