export default {
    header1: "Bonjour, je suis ",
    header2: 'Julien Souki-Léon',
    title1: 'Mon ',
    title2: 'portfolio ',
    title3: "n'est pas encore terminé",
    subtitle: "En attendant, faisons connaissance",
    link1: "N'hésitez pas à visiter mon profil linkedIn",
    link2: "Contactez moi par email",
};
