import React, { useContext, useEffect, useLayoutEffect } from 'react';
import TopBar from './top-bar/TopBar';
import { palette } from '../../utils/toolbox/palette';
import Footer from '../layout/footer/Footer';
import { menuItems } from '../../utils/menu/menu-items';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { AppContext } from '../../state/app.context';
import ResponsiveAdapter from '../utils/ResponsiveAdapter';
import { useHistory, useLocation } from 'react-router';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { isHome } from '../../utils/URL/url-utils';

const styles = (theme: Theme) =>
  createStyles({
      '@global': {
        'html, body': {
          margin: 0,
          height: '100%',
          width: '100%',
        },
        'a': {
           textDecoration: 'unset',
        },
        '#root': {
          [theme.breakpoints.down("sm")]: {
            height: '100%',
          },
        }
      },
      mainContainer: {
        background: palette.background,
        [theme.breakpoints.down('xs')]: {
          minHeight: '100%',
        },
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 3em - 48px - 5em - 33px)',
        padding: '0 3em',
        [theme.breakpoints.down('md')]: {
          minHeight: 'calc(100vh - 3em - 48px - 4em - 33px)',
        },
      },
      mobileContainer: {
        padding: '0 1.5em',
        marginBottom: theme.spacing(3),
        minHeight: 'calc(100vh - 3em - 40px - 2em - 46px)',
        [theme.breakpoints.down('xs')]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
      },
      wipMobileContainer: {
        [theme.breakpoints.down('xs')]: {
          padding: '0 1.5em',
          minHeight: 'calc(100% - 3em - 40px - 2em - 30px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
      },
      pageName: {
        position: 'fixed',
        top: '50%',
        left: '2em',
        transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
        color: '#4D4D4D',
        letterSpacing: 2,
        fontSize: 18,
        textTransform: 'uppercase',
        zIndex: 0,
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      }
  });

interface Props extends WithStyles<typeof styles> {
  showNav: boolean;
}

const Page: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {
  const { language } = useContext(AppContext);
  const { classes, children, showNav } = props;
  const location = useLocation();
  const history = useHistory();

  const getCurrentPage = (): string => {
    for (const item of menuItems) {
      if (location.pathname === item.url || (isHome(item.url, language) && location.pathname.includes(item.url))) {
        return item.title[language];
      }
    }
    return '';
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (location.pathname !== '/projects' && queryParams.has('filter')) {
      queryParams.delete('filter');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [location]);


  const appearanceAnimation = useSpring({
    from: { opacity: 0 }, 
    to: { opacity: 1 },
    config: { duration: 300 },
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
      <div className={classes.mainContainer}>
        <animated.div style={appearanceAnimation}>
          <TopBar showNav={showNav} />
          {/* NOTE: Disabling for now <Typography className={classes.pageName}>{getCurrentPage()}</Typography>*/}
          <ResponsiveAdapter
            desktop={<div className={classes.container}></div>}
            mobile={
            <div
              className={classes.mobileContainer}
              style={{
                justifyContent: (location.pathname === `/${language}` || location.pathname === `/${language}/`)
                  ? 'center'
                  : 'flex-start'
              }}
            ></div>}
          >
            <>
              {children}
            </>
          </ResponsiveAdapter>
          <Footer />        
        </animated.div>  
      </div>
  );
};

export default withStyles(styles)(Page);
