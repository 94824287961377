import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { TextField, Paper, PaperProps } from '@material-ui/core';
import { palette } from '../../utils/toolbox/palette';
import { uniqBy } from 'lodash';
import { OptionItem } from '../../interfaces/interfaces';

const styles = () =>
  createStyles({
    root: {
      '& .MuiInput-underline:before': {
        display: 'none',
      },
      '& .MuiInput-underline:after': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        color: palette.texts.light,
      },
      '& .MuiButtonBase-root': {
        display: 'none',
      },
      '& .MuiChip-root': {
        height: 26,
        backgroundColor: '#444444',
        color: '#fff',
      },
      '& .MuiChip-deleteIcon': {
        color: '#777777',
      },
      '& .MuiChip-deleteIcon:hover': {
        color: '#fff',
      },
      '& .MuiAutocomplete-tag': {
        color: '#fff',
      },
      height: 26,
    },
    input: {
      "& input::placeholder": {
        color: palette.texts.light,
        opacity: 1,
      },
    },
  });

interface Props extends WithStyles {
  options: { value: string; label: string }[];
  placeholder?: string;
  value: OptionItem[];
  setValue: (value: OptionItem[]) => void; 
}

const CustomPaper = (props: PaperProps, value: { value: string; label: string }[]) => {
  return value.length < 5 ? <Paper style={{ backgroundColor: '#444444', color: '#fff' }} elevation={8} {...props} /> : <></>;
};

const AutomcompleteInput: React.FunctionComponent<Props> = (props: Props) => {
  const { classes, options, placeholder, value, setValue } = props;
 
  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="multiple-limit-tags"
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionDisabled={(option) => value.find(o => o.label === option.label) ? true : false}
        PaperComponent={(paperProps) => CustomPaper(paperProps, value)}
        value={value}
        className={classes.input}
        onChange={(_e, newValue: OptionItem[]) => newValue.length <= 5 && setValue(uniqBy(newValue, 'value'))}
        renderInput={(params) => (
          <TextField  
            {...params}
            placeholder={value.length === 0 ? placeholder : undefined}
            classes={{ root: classes.input }}
          />
        )}
      />
    </div>
  );
}

export default withStyles(styles)(AutomcompleteInput);