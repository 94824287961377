import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { InputLabel, MenuItem, FormControl, Select as MSelect, InputBase } from '@material-ui/core';
import { OptionItem } from '../../interfaces/interfaces';

const BootstrapInput = withStyles(() =>
  createStyles({
    root: {
      '& .MuiSelect-icon': {
        color: "#fff",
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      backgroundColor: '#333333',
      color: '#fff',
      textTransform: 'capitalize',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),

    },
  }),
)(InputBase);

interface Props {
  value: any;
  options: OptionItem[];
  setValue: (value: any) => void;
}

const Select: React.FunctionComponent<Props> = (props: Props) => {
  const { options, value, setValue } = props;

  return (
    <FormControl>
      <MSelect
        value={value}
        onChange={(event) => setValue(event.target.value as string)}
        defaultValue={options[0].value}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          getContentAnchorEl: null,
        }}
        input={<BootstrapInput />}
      >
        {options.map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
      </MSelect>
    </FormControl>
  );
}

export default Select;