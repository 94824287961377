import React, { useState } from 'react';
import LanguageSwitch from './LanguageSwitch';
import { Grid, Typography, IconButton, Slide } from '@material-ui/core';
import { palette } from '../../../utils/toolbox/palette';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import LeftMenu from '../left-menu/LeftMenu';

const styles = (theme: Theme) =>
  createStyles({
      topBarContainer: {
        padding: '2em 2em 1em',
        color: palette.texts.selected,
        [theme.breakpoints.down('xs')]: {
          padding: '1em 1.5em 1em',
        },
      },
      logo: {
        fontSize: '2rem',
        fontWeight: 'bolder',
        [theme.breakpoints.down('md')]: {
          fontSize: '1.7em',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '1.3rem',
        },
      },
      navbarIcon: {
        color: '#fff',
        zIndex: 2,
      },
      navbarIconContainer: {   
        paddingLeft: 0,
      },
      leftMenuContainer: {
        position: 'fixed',
        width: 350,
        height: '100vh',
        backgroundColor: '#242424',
        top: 0,
        left: 0,
        zIndex: 3,
        overflowY: 'auto',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      blurryEffect: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        opacity: .5,
        filter: 'blur(3px)',
        zIndex: 2,
      }
  });

interface Props extends WithStyles<typeof styles> {
  showNav: boolean;
}

const TopBar: React.FunctionComponent<Props> = (props: Props) => {
  const { classes, showNav } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openOrCloseMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && <div className={classes.blurryEffect}></div>}
      <Grid container direction='row' alignItems='center' justify='space-between' className={classes.topBarContainer}>
        <Grid item>
          {!showNav && (<Typography className={classes.logo}>jsl</Typography>)}
          {showNav && (
            <IconButton
              style={{ opacity: isOpen ? 0 : 1 }}
              className={classes.navbarIconContainer}
              onClick={openOrCloseMenu}
            >
              <MenuIcon className={classes.navbarIcon} />
            </IconButton>
          )}
        </Grid>
        <Grid item>
          <Slide direction='right' in={isOpen} mountOnEnter unmountOnExit >
            <div className={classes.leftMenuContainer}>
              <LeftMenu openOrCloseMenu={openOrCloseMenu} />
            </div>
          </Slide>
        </Grid>
        <Grid item>
          <LanguageSwitch />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(TopBar);
