import { Languages } from "../../config/languages";
import { MultiLanguageElement, Tag } from "../../interfaces/interfaces";

const tagsTranslations: Record<Tag, MultiLanguageElement<string> | string> = {
  [Tag.CS]: { en: 'computer science', fr: 'informatique', proute: '' },
  [Tag.CSS]: 'CSS',
  [Tag.NODEJS]: 'node.js',
  [Tag.DESIGN]: 'design',
  [Tag.FORENSICS]: { en: 'forensics', fr: 'criminalistique informatique' },
  [Tag.MSSQL]: 'mssql',
  [Tag.REACT]: 'react',
  [Tag.REACT_NATIVE]: 'react native',
  [Tag.MSSQL]: 'mssql',
  [Tag.MYSQL]: 'mysql',
  [Tag.PYTHON]: 'python',
  [Tag.SQL]: 'SQL',
  [Tag.TYPESCRIPT]: 'typescript',
  [Tag.JAVASCRIPT]: 'javascript',
  [Tag.JAVA]: 'java',
  [Tag.HTML]: 'HTML',
  [Tag.SCHOOL]: { en: 'school', fr: 'école'},
  [Tag.DOT_NET]: '.NET',
  [Tag.MASTER]: { en: "master's degree", fr: 'master' },
  [Tag.AWS]: 'AWS',
  [Tag.PHP]: 'PHP',
  [Tag.GIT]: 'git',
  [Tag.WP]: 'wordpress',
  [Tag.MONGODB]: 'mongoDB',
  [Tag.CPP]: 'C++',
  [Tag.VB]: 'vb.net',
};

export const translateTag = (tag: Tag, language: Languages): string => {
  return typeof tagsTranslations[tag] === 'string'
    ? tagsTranslations[tag] as string
    : (tagsTranslations[tag] as MultiLanguageElement<string>)[language];
};
