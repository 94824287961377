import fr from './fr';
import en from './en';
import { Languages, LanguagesManager } from '../../../../config/languages';

export interface Definition {
  back: string;
  hint: [string, string, string];
}

export default new LanguagesManager<Definition>({
  [Languages.FRENCH]: fr,
  [Languages.ENGLISH]: en,
});
