import fr from './fr';
import en from './en';
import { Languages, LanguagesManager } from '../../../../config/languages';

export interface Definition {
  jobTitle: string;
  aboutMeTitle: string;
  aboutMe1: string;
  aboutMe2: string;
  skillsTitle: string;
}

export default new LanguagesManager<Definition>({
  [Languages.FRENCH]: fr,
  [Languages.ENGLISH]: en,
});
