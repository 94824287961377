export default {
    header1: "Hello, I'm ",
    header2: 'Julien Souki-Léon',
    title1: 'My ',
    title2: 'portfolio ',
    title3: 'is still a work in progress',
    subtitle: "In the meantime, let's connect",
    link1: 'Feel free to visit my LinkedIn profile',
    link2: 'Contact me at julien.soukileon@gmail.com',
};
