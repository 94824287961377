import { Languages } from "../../config/languages";
import { ProjectType, ProjectCategory } from "../../interfaces/interfaces";

const definitionsEN = {
  all: 'all',
  [ProjectType.JOB]: 'job',
  [ProjectType.PERSO]: 'side project',
  [ProjectType.SCHOOL]: 'school project',
  [ProjectType.OTHER]: 'other'
};

const definitionsFR = {
  all: 'tous',
  [ProjectType.JOB]: 'emploi',
  [ProjectType.PERSO]: 'projet personnel',
  [ProjectType.SCHOOL]: 'projet scolaire',
  [ProjectType.OTHER]: 'autres',
};

const translations = {
  [Languages.ENGLISH]: definitionsEN,
  [Languages.FRENCH]: definitionsFR,
};

export const translateCategory = (category: ProjectCategory, language: Languages, plural?: boolean): string => {
  return translations[language][category]
    .split(' ')
    .map(
      (word, index) =>
        `${word}${
          plural &&
          word !== 'tous' &&
          word !== 'all' &&
          (language === Languages.FRENCH || index !== 0 || word === 'job')
            ? 's'
            : ''}`,
    )
    .join(' ');
};
