import { createContext } from 'react';
import { Languages } from '../config/languages';

export const AppContext = createContext<{
    language: Languages,
    setLanguage: (lang: Languages) => void,
}>({
    language: undefined as any,
    setLanguage: () => {}, 
});
