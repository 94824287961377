import { Tag, Project, ProjectType } from '../interfaces/interfaces';

import SeeTicketsThumbnail from '../assets/st_thumbnail.png';
import YabeThumbnail from '../assets/yabe_thumbnail.png';
import LoukiThumbnail from '../assets/louki_thumbnail.png';
import VozikThumbnail from '../assets/vozik_thumbnail.png';
import MJEThumbnail from '../assets/mje_thumbnail.png';
import BVThumbnail from '../assets/bv_thumbnail.png';
import EpitechThumbnail from '../assets/epitech_thumbnail.png';
import CSULBThumbnail from '../assets/csulb_thumbnail.png';
import VOThumbnail from '../assets/viaccess-orca_thumbnail.png';
import PreppyThumbnail from '../assets/preppysport_thumbnail.png';
import SmardtvThumbnail from '../assets/smardtv_thumbnail.png';

import STLogo from '../assets/st_logo.png';
import VozikLogo from '../assets/vozik_logo.png';
import YabeLogo from '../assets/yabe_logo.png';
import LoukiLogo from '../assets/louki_logo.png';
import SmarDTVLogo from '../assets/smardtv_logo.png';
import CSULBLogo from '../assets/csulb_logo.png';
import VOLogo from '../assets/viaccess-orca_thumbnail.png';
import PreppysportLogo from '../assets/preppysport_logo.png';
import BVLogo from '../assets/bv_thumbnail.png';
import MJELogo from '../assets/mje_thumbnail.png';

import VozikHover from '../assets/vozik_hover.png';
import YabeHover from '../assets/yabe_hover.png';
import LoukiHover from '../assets/louki_hover.png';
import BVHover from '../assets/bv_hover.jpg';
import SmarDTVHover from '../assets/smardtv_hover.png';
import PreppysportHover from '../assets/preppysport_hover.png';
import ViaccessOrcaHover from '../assets/viaccess-orca_hover.png';
import STHover from '../assets/seetickets_hover.png';
import MJEHover from '../assets/mje_hover.png';

import YabeImage1 from '../assets/yabe_image1.png';
import YabeImage2 from '../assets/yabe_image2.png';

import MJEImage1 from '../assets/MJE_image1.png';

import BVImage1 from '../assets/BV_image1.jpg';
import BVImage2 from '../assets/BV_image2.png';

import EpitechImage1 from '../assets/epitech_image1.jpg';

import CSULBImage1 from '../assets/csulb_image1.jpg';
import CSULBImage2 from '../assets/csulb_image2.jpg';

const SeeTickets: Project = {
  name: 'See Tickets',
  jobTitle: [
    {
      en: 'Tech lead (6 months)',
      fr: 'Tech lead (6 months)',
    },
    {
    en: 'Software engineer (2 years)',
    fr: 'Développeur (2 ans)',
  }],
  type: ProjectType.JOB,
  logo: '',
  thumbnail: SeeTicketsThumbnail,
  tags: [Tag.REACT, Tag.NODEJS, Tag.TYPESCRIPT, Tag.AWS, Tag.VB, Tag.MONGODB, Tag.MSSQL],
  website: 'seetickets.us',
  context: {
    en: 'Working remotely as a full-stack tech lead from France and for See Tickets North America.',
    fr: 'Développeur full-stack à temps plein depuis la France pour See Tickets North America.',
  },
  dateStart: {
    en: 'April 2020',
    fr: 'Avril 2020',
  },
  dateEnd: {
    en: 'now',
    fr: "aujourd'hui",
  },
  description: {
    en: 'I currently work for See Tickets North America as a fullstack tech lead. I am part of a department of about 30 people, located in various places in the US, latin America and in Europe.\n\nI lead a team composed of three developers, a manual tester, and a product manager. My role is to lead meetings, review pull requests, ensure the completion of sprints within the deadlines imposed on us and plan the releases with the other teams and my manager. I take my role within the team to heart, and make it my number one priority to help, listen and support each of the members.\n\nAs a fullstack software engineer, I worked on different applications, mainly in a react/node.js (using typescript) and vb.net/c# stack. I have developed integrations with third party services in the context of commercial partnerships, worked with an iOS developer by providing him with the endpoints he needed, added or stabilized many features related to the ticketing industry and so on. As part of my duties, I had to get familiar with AWS tools such as SQS or lambda functions, investigate and fix bugs in production with different levels of urgency or give my opinion on candidates during final job interviews.\n\nAll this with a time difference of nine hours, which can finally be an asset when you know how to organize yourself accordingly.',
    fr: "Je travaille actuellement pour See Tickets North America en tant que tech lead fullstack. Je fais partie d'un département d'environ 30 personnes, réparties aux Etats-Unis, en Amérique Latine et en Europe.\n\nJe dirige une équipe composée de trois développeurs, un testeur manuel, ainsi qu'un product manager. Mon rôle et d'animer les réunions, assurer la complétion des sprints dans les délais qui nous sont imposés et planifier les mises en production avec les autres équipes et mon manager. Je prends à coeur mon rôle au sein de l'équipe, et met un point d'honneur à apporter de l'aide, de l'écoute et du soutien à chacun des membres qui la composent.\n\nEn tant que développeur fullstack, j'ai travaillé sur différentes applications, principalement dans une stack react/node.js (en utilisant typescript) et vb.net/c#. J'ai été amené à développer des intégrations à des services tiers dans le cadre de partenariats commerciaux, travaillé en binôme avec un développeur iOS en lui fournissant les endpoints dont il avait besoin, ajouté ou stabilisé de nombreuses fonctionnalités liées au domaine de la billetterie etc... Dans le cadre de mes fonctions, j'ai dû me familiariser avec des outils AWS tels que les SQS ou bien les fonctions lambda, enquêter et régler des bugs en production avec différents niveaux d'urgence ou encore donner mon avis sur des candidats lors d'entretiens d'embauches finaux.\n\nLe tout avec une différence horaire de neuf heures, ce qui peut finalement s'avérer être un atout lorsque l'on sait s'organiser en conséquence.",
  },
  images: [],
  projectsPage: {
    logo: STLogo,
    overrideWidthMobile: 250,
    backgroundColor: '#fff',
    overrideWidth: '70%',
    hoverImage: STHover,
  },
};

const Yabe: Project = {
  name: 'Yabe',
  jobTitle: [{
    en: 'Front-end software engineer',
    fr: 'Développeur front-end',
  }],
  type: ProjectType.JOB,
  logo: '',
  thumbnail: YabeThumbnail,
  tags: [Tag.REACT, Tag.TYPESCRIPT],
  website: 'yabe.co',
  context: {
    en: 'Working remotely as a front-end software engineer from France and for Yabe, based in Luxembourg.',
    fr: 'Développeur front-end en remote pour Yabe, plateforme de e-commerce basée au Luxembourg.',
  },
  dateStart: {
    en: 'October 2019',
    fr: 'Octobre 2019',
  },
  dateEnd: {
    en: '6 months',
    fr: '6 mois',
  },
  description: {
    en: 'Yabe is an e-commerce platform whose main goal is to promote local businesses. Instead of suggesting a list of products to the users, it gives them the possibility to express a need, which can be answered by the store owners located nearby.\n\nDuring my last year of studies, I worked six months full time as a front-end developer for Yabe. It was my first freelance and remote experience, and my responsabilites were to integrate mock-ups made by a designer into the React application, work on a messaging system, and integrate the Google Maps API.',
    fr: "Yabe est une plateforme de e-commerce dont le principal objectif est de promouvoir les commerces locaux. Au lieu de suggérer une liste de produits aux utilisateurs, elle leur donne la possibilité d'exprimer un besoin, auquel peuvent répondre les commerçants situés à proximité.\n\nPendant ma dernière année d'études, j'ai travaillé six mois à temps plein en tant que développeur front-end pour Yabe. C'était ma première expérience en freelance et à distance, et mes responsabilités étaient d'intégrer les maquettes réalisées par un designer, de travailler sur un système de messagerie et d'intégrer l'API Google Maps.",
  },
  images: [YabeImage1, YabeImage2],
  projectsPage: {
    logo: YabeLogo,
    backgroundColor: '#f7535d',
    overrideWidthMobile: 220,
    hoverImage: YabeHover,
  },
};

const louki: Project = {
  name: 'Louki',
  type: ProjectType.SCHOOL,
  jobTitle: [{
    en: 'Fullstack software engineer',
    fr: 'Développeur fullstack',
  }],
  logo: '',
  thumbnail: LoukiThumbnail,
  tags: [Tag.REACT, Tag.NODEJS, Tag.TYPESCRIPT, Tag.MONGODB],
  github: 'github.com/juliensouki/Louki',
  context: {
    en: "Open source music player developed during my master's degree.",
    fr: 'Music player open source développé durant mon master.',
  },
  dateStart: {
    en: 'September 2018',
    fr: 'Septembre 2018',
  },
  dateEnd: {
    en: '1 year',
    fr: '1 an',
  },
  description: {
    en: "Louki was a final year project during my master degree at Epitech. It was a free project to be validated by the school's teaching staff. I chose to work on a music player, a web application, that I named Louki.\n\nThe code is written in React and Node.js, with a local mongoDB database. I developed many features, such as loading metadata and playing audio files (many file types are supported), creating and managing playlists, a search algorithm or displaying music by artist or album.",
    fr: "Louki était un projet de fin d'études lors de mon master à Epitech. Il s'agissait d'un projet libre à valider par l'équipe pédagogique de l'école. J'ai choisi de travailler sur un music player, une application web, que j'ai nommé Louki.\n\nLe code est écrit en React et Node.js, avec une base de données locale mongoDB. J'ai développé de nombreuses fonctionnalités, comme le chargement de métadonnées et la lecture de fichiers audio (de nombreux types de fichiers sont pris en charge), la création et la gestion de playlists, un algorithme de recherche ou l'affichage de la musique par artiste ou par album.",
  },
  images: [],
  projectsPage: {
    logo: LoukiLogo,
    backgroundColor: '#2D2D2D',
    overrideWidth: '90%',
    overrideWidthMobile: 200,
    hoverImage: LoukiHover,
  },
};

const myLogger: Project = {
  name: 'mylogger',
  type: ProjectType.PERSO,
  jobTitle: [{
    en: 'Fullstack software engineer',
    fr: 'Développeur fullstack',
  }],
  logo: '',
  thumbnail: VozikThumbnail,
  tags: [Tag.REACT, Tag.NODEJS, Tag.TYPESCRIPT, Tag.MYSQL, Tag.DESIGN],
  context: {
    en: "Web application allowing to centralize the logs of multiple applications",
    fr: 'Application web permettant de centraliser les logs de ses applications',
  },
  dateStart: {
    en: 'November 2022',
    fr: 'Novembre 2022',
  },
  dateEnd: {
    en: '2023 ?',
    fr: '2023 ?',
  },
  description: {
    en: "This project is in progress, and I hope to have it ready within next year. My objective is mainly to work on all parts of a tech project in order to discover and learn new things, in tech but also design, product etc.. I will consider it a success if it generates enough money to not cost me money, while being useful to others.\n\nI first wrote a PRD, and created tasks for every feature on jira, to track my progress. Along with this, I designed all of the pages using figma, in a light and a dark theme. I defined the endpoints needed in the API in order to make these features possible, deployed a MySQL database, and setup a staging environment for development. The code lives in a monorepo because both the API and web application will be versioned together. It also includes packages shared by these two apps. Each endpoint comes with unit tests and is protected by various middlewares (authentication, privilegies etc...). The changes made on the staging branch are automatically deployed by the CI.\n\nI'm trying to work on it whenever I get some free time, and I'm excited to make more progress 😄",
    fr: "Ce projet est en cours, et j'espère qu'il sera prêt courant de l'année prochaine. Mon objectif est principalement de travailler sur toutes les parties d'un projet tech pour découvrir et apprendre de nouvelles choses, liées à la tech mais aussi au design, product management etc. Je considérerai que c'est un succès s'il génère suffisamment d'argent pour ne pas m'en coûter, tout en étant utile aux autres.\n\nJ'ai d'abord écrit un PRD, et créé des tâches pour chaque fonctionnalité sur jira, pour suivre ma progression. En même temps, j'ai designé toutes les pages en utilisant figma, dans un thème light et un thème dark. J'ai défini les routes nécessaires dans l'API afin de rendre ces fonctionnalités possibles, déployé une base de données MySQL et configuré un environnement de développement de staging. Le code se trouve dans un monorepo car l'API et l'application web seront versionnées ensemble. Il comprend également des packages partagés par ces deux applications. Chaque route est accompagnée de tests unitaires et est protégée par divers middlewares (authentification, privilèges etc...). Les changements push sur la branche staging sont automatiquement déployés par la CI\n\nJ'essaie de travailler sur ce projet dès que j'ai un peu de temps libre, et je suis impatient de faire de continuer à avancer dessus 😄",
  },
  images: [],
  projectsPage: {
    logo: VozikLogo,
    backgroundColor: '#683076',
    overrideWidth: '70%',
    overrideWidthMobile: 165,
    hoverImage: VozikHover,
  },
};

const MJE: Project = {
  name: 'My Jolly Event',
  type: ProjectType.JOB,
  jobTitle: [{
    en: 'Wordpress developer',
    fr: 'Développeur wordpress',
  }],
  logo: '',
  thumbnail: MJEThumbnail,
  tags: [Tag.WP, Tag.JAVASCRIPT, Tag.PHP, Tag.MYSQL, Tag.HTML, Tag.CSS],
  context: {
    en: "Wordpress website developed, deployed and maintained.",
    fr: "Responsable du développement, déploiement et de la maintenance d'un site wordpress.",
  },
  dateStart: {
    en: 'July 2020',
    fr: 'Juillet 2020',
  },
  dateEnd: {
    en: '3 months',
    fr: '3 mois',
  },
  description: {
    en: "Creation of a wordpress site from scratch and integration of mockups provided by MJE (My Jolly Event).\n\nDevelopment of several wordpress plugins, the most important allowing the management of the quote requests received via a form accessible on the website.\n\nUsing Github Actions allowing to automatically deploy the changes on the server of the company.\n\nUPDATE: The company does not exist anymore, but the owner asked me to move it to a free hosting server and domain, so that it remains accessible.",
    fr: "Création d'un site wordpress from scratch et intégration de maquettes fournies par MJE (My Jolly Event).\n\nDéveloppement de plusieurs plugins wordpress, le plus important permettant la gestion des demandes de devis reçus via un formulaire accessible sur le site.\n\nUtilisation des Github Actions permettant de déployer automatiquement les changements effectués sur le site sur le serveur de l'entreprise.\n\nMAJ: L'entreprise n'existe plus, mais la fondatrice m'a demandé de déplacer le site sur un serveur et un domaine gratuits, pour qu'il reste accessible.",
  },
  website: 'http://myjollyevent.atwebpages.com',
  images: [MJEImage1],
  projectsPage: {
    logo: MJELogo,
    backgroundColor: '#e4edff',
    overrideWidth: '35%',
    overrideWidthMobile: 70,
    hoverImage: MJEHover,
  },
};

const BV: Project = {
  name: 'Bureau Valentina',
  type: ProjectType.JOB,
  jobTitle: [{
    en: 'Wordpress developer',
    fr: 'Développeur fullstack',
  }],
  logo: '',
  thumbnail: BVThumbnail,
  tags: [Tag.WP, Tag.JAVASCRIPT, Tag.PHP, Tag.MYSQL, Tag.HTML, Tag.CSS, Tag.DESIGN],
  website: 'bureau-valentina.fr',
  context: {
    en: "Wordpress website developed, deployed and maintained.",
    fr: "Responsable du développement, déploiement et de la maintenance d'un site wordpress.",
  },
  dateStart: {
    en: 'February 2020',
    fr: 'Février 2020'
  },
  dateEnd: {
    en: '3 months',
    fr: '3 mois',
  },
  description: {
    en: "Creation of a wordpress website, set up for the company Bureau Valentina, owned by my parents. I made custom mockups for them, which we modified together according to their wishes.\n\nI integrated these mockups and I also set up Github actions in order to automatically deploy the changes on the company's server.",
    fr: "Création d'un site internet wordpress, mis en place pour l'entreprise Bureau Valentina, appartenant à mes parents. J'ai réalisé avec eux des maquettes personnalisées, que nous avons modifiées ensemble en fonction de leurs souhaits.\n\nJ'ai intégré ces maquettes et j'ai également mis en place des actions Github afin de déployer automatiquement les modifications sur le serveur web de l'entreprise.",
  },
  images: [BVImage1, BVImage2],
  projectsPage: {
    logo: BVLogo,
    backgroundColor: '#e7d6d4',
    overrideWidth: '30%',
    overrideWidthMobile: 70,
    hoverImage: BVHover,
  },
};

const smarDTV: Project = {
  name: 'SmarDTV',
  type: ProjectType.JOB,
  jobTitle: [{
    en: 'Web developer',
    fr: 'Développeur web',
  }],
  logo: '',
  thumbnail: SmardtvThumbnail,
  tags: [Tag.HTML, Tag.CSS, Tag.JAVASCRIPT, Tag.MYSQL, Tag.PYTHON],
  website: 'smardtv.com',
  context: {
    en: "Six months full-time QA software engineering internship.",
    fr: "Stage de six mois à temps plein de développeur QA.",
  },
  dateStart: {
    en: 'June 2016',
    fr: 'Juin 2016',
  },
  dateEnd: {
    en: '6 months',
    fr: '6 mois',
  },
  description: {
    en: "I integrated the QA team of the company. I was co-responsible for the development of two internal websites, with another intern developer. The first website was an equipment reservation portal, and the second one was an interface to control a remote decoder, allowing to make recordings on decoders located in other countries and to upload them automatically on the FTP server of the company to check their quality before putting them on foreign markets.\n\nWe also wrote unit tests in python to control the quality of the new decoders.",
    fr: "J'ai intégré l'équipe QA de l'entreprise. J'étais co-responsable du développement de deux sites web internes, avec un autre développeur stagiaire. Le premier site était un portail de réservation d'équipements, et le second était une interface de contrôle d'un décodeur à distance, permettant de faire des enregistrements sur des décodeurs situés dans d'autres pays et de les uploader automatiquement sur le serveur FTP de l'entreprise pour vérifier leur qualité avant de les mettre en vente sur les marchés étrangers.\n\nNous avons également écrit des tests unitaires en python pour contrôler la qualité des nouveaux décodeurs.",
  },
  images: [],
  projectsPage: {
    logo: SmarDTVLogo,
    backgroundColor: '#d55331',
    overrideWidth: '100%',
    overrideWidthMobile: 235,
    hoverImage: SmarDTVHover,
  },
};

const preppysport: Project = {
  name: 'Preppysport',
  type: ProjectType.JOB,
  jobTitle: [{
    en: 'Wordpress developer',
    fr: 'Développeur wordpress',
  }],
  logo: '',
  thumbnail: PreppyThumbnail,
  tags: [Tag.WP, Tag.PHP, Tag.HTML, Tag.CSS, Tag.MYSQL, Tag.REACT_NATIVE],
  website: 'preppysport.com',
  context: {
    en: "Five months part-time wordpress developement internship.",
    fr: 'Stage de cinq mois à temps partiel en tant que développeur wordpress.',
  },
  dateStart: {
    en: 'October 2017',
    fr: 'Octobre 2017',
  },
  dateEnd: {
    en: '5 months',
    fr: '5 mois',
  },
  description: {
    en: "Preppysport is an e-commerce website that sells second hand horse-riding equipment. At the time I joined, we were only two developers, the CTO and myself.\n\nI worked on the wordpress website, adding new pages and fixing issues and I also started the development of a small mobile application using React Native, enabling Preppysport staff to take pictures of second hand equipment they were storing in order to keep the inventory up to date.",
    fr: "Preppysport est un site de e-commerce qui vend du matériel d'équitation d'occasion. Au moment où je l'ai rejoint, nous n'étions que deux développeurs, le CTO et moi-même.\n\nJ'ai travaillé sur le site web wordpress, en ajoutant de nouvelles pages et en corrigeant des bugs. J'ai également commencé le développement d'une application mobile interne écrite en react-native, permettant au personnel de Preppysport de prendre des photos du matériel d'occasion qu'ils stockent afin de tenir l'inventaire à jour.",
  },
  images: [],
  projectsPage: {
    logo: PreppysportLogo,
    backgroundColor: '#000000',
    overrideWidth: '100%',
    overrideWidthMobile: 235,
    hoverImage: PreppysportHover,
  },
};

const viaccess: Project = {
  name: 'Viaccess-Orca',
  type: ProjectType.JOB,
  jobTitle: [{
    en: 'C++/Java developer ',
    fr: 'Développeur C++/Java',
  }],
  logo: '',
  thumbnail: VOThumbnail,
  tags: [Tag.JAVA, Tag.CPP],
  website: 'viaccess-orca.com',
  context: {
    en: "Four months full-time software engineering internship.",
    fr: 'Stage de développeur informatique de quatre mois à temps plein.',
  },
  dateStart: {
    en: 'April 2018',
    fr: 'Avril 2018',
  },
  dateEnd: {
    en: '4 months',
    fr: '4 mois',
  },
  description: {
    en: "I worked on a component that is part of a complex security system used by some TV channels to make sure that only subscribers access paid content. This component was written in C twenty years prior to my internship. It was very hard to maintain and the goal of the internship was to develop a light version of this component in two different programming languages, in order to run a batch of tests on both of them to determine which version was the best for the company to use.\n\nDuring these four months, I talked with a senior software architect, to determine how to build our Java and C++ prototypes and decide what libraries to use. I worked independently and was able to finish the two protoypes just before the internship ended.",
    fr: "J'ai travaillé sur un composant qui fait partie d'un système de sécurité complexe utilisé par certaines chaînes de télévision pour s'assurer que seuls les abonnés accèdent au contenu payant. Ce composant était écrit en C, vingt ans avant mon stage. Il était très difficile à maintenir et l'objectif du stage était de développer une version allégée de ce composant dans deux langages de programmation différents, afin d'effectuer un lot de tests sur les deux prototypes pour déterminer quelle version répondait le mieux aux besoins de l'entreprise en terme de performances.\n\nPendant ces quatre mois, j'ai discuté avec un architecte logiciel senior, pour déterminer comment construire nos prototypes Java et C++ et décider des librairies à utiliser. Je les ai développés de manière indépendante et j'ai pu terminer les deux prototypes juste avant la fin du stage.",
  },
  images: [],
  projectsPage: {
    logo: VOLogo,
    backgroundColor: '#F6F6F6',
    overrideWidth: '45%',
    overrideWidthMobile: 100,
    hoverImage: ViaccessOrcaHover,
  },
};

const CSULB: Project = {
  name: 'CSULB',
  type: ProjectType.OTHER,
  jobTitle: [{
    en: 'Exchange student',
    fr: 'Etudiant international',
  }],
  logo: '',
  thumbnail: CSULBThumbnail,
  tags: [Tag.SCHOOL, Tag.CS, Tag.JAVASCRIPT, Tag.FORENSICS],
  website: 'csulb.edu',
  context: {
    en: "Studied two semesters in the California State University of Long Beach as an exchange student.",
    fr: "Etudes pendant deux semestres à la California State University de Long Beach en Californie en tant qu'étudiant international.",
  },
  dateStart: {
    en: '2018/2019',
    fr: '2018/2019',
  },
  dateEnd: {
    en: '2 semesters',
    fr: '2 semestres',
  },
  description: {
    en: "The first semester consisted of English classes with the other students of my class, in a program called the American Language Institute. We studied English as well as the American culture.\n\nThe second semester was dedicated to programming classes. I took two web development classes (one of them was mainly focused on accessibility), a computer forensics class that allowed us to practice investigation techniques and find hidden evidence on devices, and finally a marketing class.",
    fr: "Le premier semestre était composé de cours d'anglais avec les autres étudiants de ma promotion, dans un programme appelé American Language Institute (ALI). Nous avons étudié l'anglais ainsi que la culture américaine.\n\nLe deuxième semestre, quant à lui, était consacré aux cours de programmation. J'ai suivi deux cours de développement Web (l'un d'eux était principalement axé sur l'accessibilité), un cours d'enquêtes informatiques qui nous a permis de pratiquer des techniques d'investigation et de trouver des preuves cachées sur des appareils, et enfin un cours de marketing.",
  },
  images: [CSULBImage1, CSULBImage2],
  projectsPage: {
    logo: CSULBLogo,
    backgroundColor: '#000000',
    overrideWidth: '40%',
    overrideWidthMobile: '50%',
    hoverImage: VozikHover,
  },
};

const epitech: Project = {
  name: 'Epitech',
  type: ProjectType.OTHER,
  jobTitle: [{
    en: "Master's degree",
    fr: "Master",
  }],
  logo: '',
  thumbnail: EpitechThumbnail,
  website: 'epitech.eu',
  tags: [Tag.SCHOOL, Tag.CS, Tag.MASTER],
  context: {
    en: "Obtained a master's degree in computer science after studying five years at Epitech Rennes.",
    fr: "Obtention d'un master en développement informatique après cinq années d'études à Epitech Rennes.",
  },
  dateStart: {
    en: 'Graduated in 2020',
    fr: 'Obtenu en 2020',
  },
  dateEnd: {
    en: '5 years',
    fr: '5 ans',
  },
  description: {
    en: "The first three years, particularly intense, were dedicated to learning the fundamental concepts of programming: algorithms, object-oriented programming, memory and many others. We did not attend classes, but had to carry out a multitude of projects, in groups or individually, mainly in C/C++ (but not exclusively).\n\nThe fourth and fifth years main focus was entrepreneurship. We worked on a project called EIP (Epitech Innovative Project). The objective was to create a startup around a technological project. One of the major constraints of this project is that the group members are spread all over the world during the fourth year.\n\n",
    fr: "Les trois premières années, particulièrement intenses, étaient dédiées à l'apprentissage de concepts fondamentaux de programmation: algorithmie, programmation ortientée objet, la mémoire et bien d'autres. Pour ce faire, nous n'assistions pas à des cours, mais devions réaliser une multitude de projets différents, en groupe ou individuellement, principalement en C/C++ (mais pas exclusivement).\n\nLes quatrième et cinquième années quant à elles, gravitent autour du thème de l'entreprenariat, grâce notamment à un projet de groupe, l'EIP, dont l'objectif est de créer une startup. L'une des contraintes majeures de ce projet, est que les membres du groupe sont éparpillés un peu partout dans le monde pendant toute la quatrième année.\n\n",
  },
  images: [EpitechImage1],
  projectsPage: {
    logo: VozikLogo,
    backgroundColor: '#232323',
    overrideWidth: '70%',
    overrideWidthMobile: '50%',
    hoverImage: VozikHover,
  },
};

export const projects = [
  SeeTickets,
  MJE,
  BV,
  myLogger,
  louki,
  epitech,
  Yabe,
  CSULB,
  viaccess,
  preppysport,
  smarDTV,
];
