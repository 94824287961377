type Font = {
  fontFamily?: string,
  fontSize: string | number,
  fontWeight?: 'normal' | 'bold' | 'inherit' | 'unset' | 'bolder' | 'lighter';
  textTransfrom?: string;
  textDecoration?: string;
};

export const fonts: {
  homePage: {
    header: Font,
    title: Font,
    subtitle: Font,
  },
  title: Font,
  subtitle: Font,
  text: Font,
  placeholder: Font,
} = {
  homePage: {
    header: {
      fontSize: '25px',
    },
    title: {
      fontSize: '42px',
      fontWeight: 'bold',
    },
    subtitle: {
      fontSize: '25px',
    },
  },
  title: {
    fontSize: 0,
  },
  subtitle: {
    fontSize: 0,
  },
  text: {
    fontSize: 0,
  },
  placeholder: {
    fontSize: 0,
  },
};