import React, { useContext } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import texts from '../../lang/pages/about-me/hobbies';
import { AppContext } from '../../state/app.context';
import { palette } from '../../utils/toolbox/palette';
import ImageLoader from '../../components/utils/image-loader';

const hoverRules = {
  '&:hover $imageContainer': {
    filter: 'blur(3px) brightness(35%)',
    cursor: "pointer",
  },
  '&:hover $imageOverlay': {
    opacity: 1,
    cursor: "pointer",
  },
};

const styles = (theme: Theme) =>
  createStyles({
    mainImageWrapper: {
      position: 'relative',
      height: 500,
      width: 500,
      [theme.breakpoints.down(1466)]: {
        margin: theme.spacing(1),
        height: 380,
        width: 380,  
      },
      [theme.breakpoints.down('sm')]: {
        height: 380,
        width: 380,
      },
      [theme.breakpoints.down('xs')]: {
        height: 182,
        width: '100%',
      },
      margin: theme.spacing(2),
      ...hoverRules,
    },
    imageWrapper: {
      position: 'relative',
      height: 234,
      width: 234,
      margin: theme.spacing(2),
      [theme.breakpoints.down(1466)]: {
        height: 182,
        width: 182,  
        margin: theme.spacing(1),
      },
      ...hoverRules,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

    },
    imageOverlay: {
      position: 'absolute',
      transition: '.3s ease-out',
      padding: theme.spacing(2),
      color: "#fff",
      zIndex: 2,
      opacity: 0,
      width: `calc(100% - ${theme.spacing(4)}px)`,
    },
    imageContainer: {
      transition: '.3s ease-out',
      width: '100%',
      height: '100%',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    divider: {
      backgroundColor: "#fff",
      opacity: .2,
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
    title: {
      fontSize: 20,
      color: palette.texts.highlight,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    smallerImagesContainer: {
      width: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    description: {
      color: '#cccccc',
      [theme.breakpoints.down(1466)]: {
        fontSize: 14,
      },
    },
  });

const MyHobbies: React.FunctionComponent<WithStyles> = (props: React.PropsWithChildren<WithStyles>) => {
  const { classes } = props;
  const { language } = useContext(AppContext);
  const T = texts.current(language);

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <div className={classes.mainImageWrapper}>
        <div className={classes.imageOverlay}>
          <Typography className={classes.title}>{T.travel.title}</Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.description}>{T.travel.description}</Typography>
        </div>
        <div className={classes.imageContainer}>
          <ImageLoader
            className={classes.image}
            src="https://images.unsplash.com/photo-1495904786722-d2b5a19a8535?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=896&q=80"
          />
        </div>
      </div>
      <Grid container direction="column" className={classes.smallerImagesContainer}>
        <Grid container direction="row">
          <div className={classes.imageWrapper}>
            <div className={classes.imageOverlay}>
              <Typography className={classes.title}>{T.dogs.title}</Typography>
              <Divider className={classes.divider} />
              <Typography className={classes.description}>{T.dogs.description}</Typography>
            </div>
            <div className={classes.imageContainer}>
              <ImageLoader
                className={classes.image}
                src="https://images.unsplash.com/photo-1587354004405-bbce24c893c8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=880&q=80"
              />
            </div>
          </div>
          <div className={classes.imageWrapper}>
            <div className={classes.imageOverlay}>
              <Typography className={classes.title}>{T.piano.title}</Typography>
              <Divider className={classes.divider} />
              <Typography className={classes.description}>{T.piano.description}</Typography>
            </div>
            <div className={classes.imageContainer}>
            <ImageLoader
                className={classes.image}
                src="https://images.unsplash.com/photo-1611071496141-fd06e4c6889c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1472&q=80"
              />
            </div>
          </div>
        </Grid>
        <Grid container direction="row">
          <div className={classes.imageWrapper}>
            <div className={classes.imageOverlay}>
              <Typography className={classes.title}>{T.soccer.title}</Typography>
              <Divider className={classes.divider} />
              <Typography className={classes.description}>{T.soccer.description}</Typography>
            </div>
            <div className={classes.imageContainer}>
              <ImageLoader
                className={classes.image}
                src="https://images.unsplash.com/photo-1568194157720-8bbe7114ebe8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1632&q=80"
              />
            </div>
          </div>
          <div className={classes.imageWrapper}>
            <div className={classes.imageOverlay}>
              <Typography className={classes.title}>{T.photo.title}</Typography>
              <Divider className={classes.divider} />
              <Typography className={classes.description}>{T.photo.description}</Typography>
            </div>
            <div className={classes.imageContainer}>
              <ImageLoader
                className={classes.image}
                src="https://images.unsplash.com/photo-1590486803833-1c5dc8ddd4c8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(MyHobbies);
