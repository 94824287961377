import React, { useState } from 'react';
import WorkInProgress from './pages/work-in-progress';
import Home from './pages/home'; 
import AboutMe from './pages/about-me/about-me-container';
import Contact from './pages/contact'; 
import MyProjects from './pages/my-projects';
import SpecificProject from './pages/specific-project'; 
import MentionsLegales from './pages/mentions-legales'; 
import Page from './components/layout/Page';
import LoadingPage from './pages/loading-page';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import queryString from 'query-string';
import { Languages } from './config/languages';
import { get } from 'lodash-es';
import { AppContext } from './state/app.context';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Md5 } from 'ts-md5/dist/md5';
import{ init as initEmailService } from 'emailjs-com';
import dotenv from 'dotenv';
import { getLanguageFromURL } from './lang/utils/URL';

const theme = createMuiTheme({});
dotenv.config();

const App: React.FunctionComponent = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [language, setLanguage] = useState<Languages>(getLanguageFromURL(window.location.pathname));
  const queryParams = get(window, 'location.search', '');
  const queryParamsParsed = queryString.parse(queryParams);

  // Display (or not) work in progress page
  const [displayWorkInProgress] = useState<boolean>(
    !(Md5.hashStr(queryParamsParsed.authorize as string || '') === '56990ac5fef591ad61163e14d83f09b5') && process.env.SHOW_WIP === 'true'
  );

  initEmailService(process.env.EMAILJS_USER_ID as string);

  if (isLoaded || displayWorkInProgress) {
    return (
      <AppContext.Provider value={{
        language,
        setLanguage,
      }}>
        <ThemeProvider theme={theme}>
          <Router>
            <Page showNav={!displayWorkInProgress}>
                {displayWorkInProgress ? (
                  <Switch>
                    <Route path={`/${language}`} component={WorkInProgress} exact />
                    <Redirect to={{ pathname: `/${language}`, search: queryParams }} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route path={`/${language}`} component={Home} exact />
                    <Route path={`/${language}/about-me/:section`} component={AboutMe} exact />
                    <Route path={`/${language}/projects`} component={MyProjects} exact />
                    <Route path={`/${language}/project/:projectId`} component={SpecificProject} exact />
                    <Route path={`/${language}/contact`} component={Contact} exact />
                    <Route path={`/${language}/mentions-legales`} component={MentionsLegales} exact />
                    <Route path="*" render={() => <Redirect to={{ pathname: `/${language}`, search: queryParams }} />} />
                  </Switch>
                )}
            </Page>
          </Router>
        </ThemeProvider>
      </AppContext.Provider>
    );
  } else {
    return <LoadingPage setIsLoaded={setIsLoaded} />;
  }
};

export default App;
