import React, { useContext, useState, useEffect, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import texts from '../../lang/pages/about-me/experiences';
import { AppContext } from '../../state/app.context';
import { projects } from '../../data/projects';
import { Project, ProjectType } from '../../interfaces/interfaces';
import ProjectDetails from '../../components/projects/project-details';
import ProjectTimeline from '../../components/projects/projects-timeline';
import { palette } from '../../utils/toolbox/palette';
import InfoIcon from '@material-ui/icons/Info';
import useWindowDimensions from "../../utils/window-dimensions/use-window-dimensions";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { animated, useSpring } from '@react-spring/web';
import Spotlight from "spotlight.js/src/js/spotlight.js";
import "spotlight.js/src/css/spotlight.css";
import { orderImageForGallery } from '../../utils/images/images-utils';
import ImageLoader from '../../components/utils/image-loader';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 1400,
    },
    descriptionContainer: {
      padding: theme.spacing(2),
      width: 700,
      minHeight: 700,
      [theme.breakpoints.down(1350)]: {
        width: 550,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "unset",
      },
    },
    timelineContainer: {
      overflowY: "auto",
      maskImage: "linear-gradient(to bottom, black 70%, transparent 100%)",
      height: "calc(100vh - 3em - 48px - 5em - 33px)",
      minHeight: 700,
      width: 500,
      scrollBehavior: 'smooth',
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
      },
      [theme.breakpoints.down("md")]: {
        width: 250,
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: 500,
        width: "100%",
        height: "unset",
        maskImage: "unset",
        marginBottom: theme.spacing(2),
        overflowY: "hidden",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: 300,
        width: "100%",
      },
    },
    projectImageContainer: {
      width: 130,
      height: 130,
      backgroundColor: palette.texts.dark,
      marginBottom: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      transition: 'all .3s',
      [theme.breakpoints.down("md")]: {
        width: 100,
        height: 100,
      },
      [theme.breakpoints.down('sm')]: {
        width: 80,
        height: 80,
      },
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.1)',
      },
    },
    projectImage: {
      objectFit: 'cover',
      height: '100%',
      maxWidth: '100%',
    },
    projectImagesContainer: {
      marginTop: theme.spacing(3),
    },
    hintContainer: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(3),
      },
    },
    hintIcon: {
      color: palette.texts.highlight,
      fontSize: 24,
      height: 24,
      width: 24,
    },
    hintText: {
      [theme.breakpoints.down("xs")]: {
        width: `calc(100% - 24px - ${theme.spacing(2)}px)`, //100% - hintIcon width - margin
      },
      width: "auto",
      marginLeft: theme.spacing(2),
      color: palette.texts.light,
      fontSize: 14,
    },
    hintBlueText: {
      color: palette.texts.highlight,
      fontSize: 14,   
      display: 'inline-block',
    },
    backButtonContainer: {
      marginBottom: theme.spacing(4),
      color: "#fff",
      '&:hover': {
        cursor: 'pointer',
      },
    },
    backText: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
    },
    backIcon: {
      fontSize: 16,
    },
  });

const timelineProjects =
  projects.filter(project => project.type !== ProjectType.PERSO && project.type !== ProjectType.SCHOOL);

const MyExperiences: React.FunctionComponent<WithStyles> = (props: React.PropsWithChildren<WithStyles>) => {
  const { classes } = props;
  const { language } = useContext(AppContext);
  const T = texts.current(language);
  const { width } = useWindowDimensions();

  const [currentProjectIndex, setCurrentProjectIndex] = useState<number>(0);
  const [showCurrentProject, setShowCurrentProject] = useState<boolean>(true);
  const [selectedExperience, setSelectedExperience] = useState<Project>(timelineProjects[0]);
  const [isMobile, setIsMobile] = useState<boolean>();
  const timelineContainerRef = useRef(null);

  useEffect(() => {
    const isNowMobile = width < 960;
    const wasMobile = isMobile;
    if (!wasMobile && isNowMobile) {
      setShowCurrentProject(false);
    }
    setIsMobile(isNowMobile);
  }, [width]);

  const [projectContainerAnimation, api] = useSpring(() => ({
    from: { opacity: 0, y: -30 }, 
    to: { opacity: 1, y: 0 },
    config: { duration: 400 },
  }));

  const projectClickHandler = (index: number, ref: React.MutableRefObject<null>) => {
    if (ref && ref.current && timelineContainerRef && timelineContainerRef.current && !isMobile) {
      const scrollInPx = (ref.current as any).offsetTop - (ref.current as any).scrollHeight - 8;
      (timelineContainerRef.current as any).scrollTo(0, scrollInPx);
    } else if (isMobile) {
      setShowCurrentProject(true);
    }
    setCurrentProjectIndex(index);
    api.start({
      from: { opacity: 0, y: -30 }, 
      to: { opacity: 1, y: 0 },
      config: { duration: 200 },  
    });
  };

  const handleBackMobileButtonClick = () => setShowCurrentProject(false);

  useEffect(() => {
    setSelectedExperience(timelineProjects[currentProjectIndex]);
  }, [currentProjectIndex]);

  useEffect(() => {
    Spotlight.init();
  }, []);

  const handleImageClick = (images: string[], startImageIndex: number): void => {
    const orderedImages = orderImageForGallery(images, startImageIndex);
    Spotlight.show(orderedImages.map(image => ({ src: image })));
  };

  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center" className={classes.root}>
      <animated.div style={projectContainerAnimation}>
        {(!isMobile || showCurrentProject) && (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justify="flex-start" 
            className={classes.descriptionContainer}
          >
            {isMobile && (
              <Grid
                container
                direction="row"
                onClick={handleBackMobileButtonClick}
                className={classes.backButtonContainer}
                alignItems="center"
              >
                <ArrowBackIosIcon className={classes.backIcon} />
                <Typography className={classes.backText}>{T.back}</Typography>
              </Grid>
            )}
            <ProjectDetails project={selectedExperience} />
            <Grid container direction="row" className={classes.projectImagesContainer}>
              {selectedExperience.images.map((image, index) => (
                <Grid
                  container
                  direction='column'
                  justify='center'
                  key={`${selectedExperience.name}-${index}`}
                  className={classes.projectImageContainer}
                  onClick={() => handleImageClick(selectedExperience.images, index)}
                >
                  <ImageLoader src={image} className={classes.projectImage} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </animated.div>
      {(!showCurrentProject || !isMobile) && (
        <Grid container direction="row" className={classes.hintContainer} alignItems="center" justify="center">
          <InfoIcon className={classes.hintIcon}/>
          <Typography className={classes.hintText}>{T.hint[0]}<Typography className={classes.hintBlueText}>{T.hint[1]}</Typography> {T.hint[2]}</Typography>
        </Grid>
      )}
      {(!showCurrentProject || !isMobile) && (
        <Grid container direction="column" item className={classes.timelineContainer} ref={timelineContainerRef}>
          <ProjectTimeline updateCurrentProject={projectClickHandler} currentProjectIndex={currentProjectIndex}/>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(MyExperiences);
