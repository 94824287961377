export enum Tag {
  NODEJS,
  REACT,
  VB,
  MSSQL,
  MYSQL,
  MONGODB,
  TYPESCRIPT,
  AWS,
  REACT_NATIVE,
  SQL,
  JAVASCRIPT,
  PHP,
  HTML,
  CSS,
  DOT_NET,
  PYTHON,
  GIT,
  WP,
  DESIGN,
  SCHOOL,
  MASTER,
  CS,
  CPP,
  JAVA,
  FORENSICS,
}

export enum ProjectType {
  PERSO,
  JOB,
  SCHOOL,
  OTHER,
}

export type ProjectCategory = ProjectType | 'all';

export type MultiLanguageElement<T> = {
  [Languages: string]: T,
};

export interface MenuItem {
  title: MultiLanguageElement<string>;
  icon: any;
  url: string;
  items?: MenuItem[];
}

export interface Project {
  name: string;
  jobTitle?: MultiLanguageElement<string>[];
  type: ProjectCategory;

  logo: string;
  thumbnail: string;

  tags: Tag[];

  context: MultiLanguageElement<string>;
  dateStart: MultiLanguageElement<string>;
  dateEnd: MultiLanguageElement<string>;

  description: MultiLanguageElement<string>;

  github?: string;
  website?: string;

  images: string[];

  projectsPage: {
    logo: string;
    backgroundColor: string;
    overrideWidth?: string | number;
    overrideWidthMobile?: string | number;
    hoverImage: string;
  },
}

export interface OptionItem {
  value: any;
  label: string;
}

export interface ContactErrors {
  name: boolean;
  email: boolean;
  message: boolean;
}

export type SkillType = 'hard' | 'soft';

export interface Skill {
  name: Tag;
  type: SkillType;
  isFav: boolean;
  image: string;
  excludeOnMySkills?: boolean;
  overrideStyle?: {
    position?: 'relative' | 'absolute';
    top?: number;
    height?: number;
    width?: number;
    sWidth?: number;
    xsWidth?: number;
  }
}