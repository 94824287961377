import React, { Dispatch, SetStateAction } from 'react';
import { ReactComponent as Loader } from '../assets/loader.svg';
import { Grid } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';

const styles = (theme: Theme) =>
  createStyles({
    loaderContainer: {
      width: '100%',
      height: '100vh',
    },
  });

interface Props extends WithStyles<typeof styles> {
  setIsLoaded: Dispatch<SetStateAction<boolean>>;
}

const Home: React.FunctionComponent<Props> = (props: Props) => {
  const { classes, setIsLoaded } = props;

  const leaveAnimation = useSpring({
    from: { opacity: 1 }, 
    to: { opacity: 0 },
    config: { duration: 300 },
    delay: 3200,
    onResolve: () => setIsLoaded(true),
  });

  return (
    <Grid container direction="column" alignItems="center" justify="center" className={classes.loaderContainer}>
      <animated.div style={leaveAnimation}>
        <Loader id="loader" style={{ overflow: 'visible' }}/>
      </animated.div>
    </Grid>
  );
};

export default withStyles(styles)(Home);
