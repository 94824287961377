import React, { useState, useEffect, useContext } from 'react';
import { Container, Grid } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import MyHobbies from './my-hobbies';
import MyResume from './my-skills';
import MyExperiences from './my-experiences';
import { palette } from '../../utils/toolbox/palette';
import { AppContext } from '../../state/app.context';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    scrollStepsContainer: {
      position: 'absolute',
      right: '3em',
      top: 'calc(50% - 35px)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    sectionCircle: {
      position: 'relative',
      backgroundColor: palette.texts.dark,
      borderRadius: '100%',
      width: 15,
      height: 15,
      marginBottom: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    innerCircle: {
      position: 'absolute',
      borderRadius: '100%',
      width: 11,
      height: 11,
      top: 2,
      left: 2,
      backgroundColor: palette.texts.dark,
    },
    aboutMeContainer: {
      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start",
      },
    },
  });

const AboutMeContainer: React.FunctionComponent<WithStyles> = (props: React.PropsWithChildren<WithStyles>) => {
  const { language } = useContext(AppContext);
  const { classes } = props;
  const history = useHistory();

  const [currentSection, setCurrentSection] = useState<number>(0);

  useEffect(() => {
    const url = window.location.href;
    if (url.includes('hobbies')) {
      setCurrentSection(2);
    } else if (url.includes('skills')) {
      setCurrentSection(0);
    } else {
      setCurrentSection(1);
    }
  }, [window.location.href]);

  const redirect = (page: string): void => {
    history.push(`/${language}/about-me/${page}${window.location.search}`);
  };

  return (
    <Container maxWidth="xl" className={classes.root}>
      <div className={classes.scrollStepsContainer}>
        <div
          className={classes.sectionCircle}
          onClick={() => redirect('skills')}
          style={{ backgroundColor: currentSection === 0 ? palette.texts.highlight : undefined }}
        >
          <div className={classes.innerCircle}></div>
        </div>
        <div
          className={classes.sectionCircle}
          onClick={() => redirect('experiences')}
          style={{ backgroundColor: currentSection === 1 ? palette.texts.highlight : undefined }}
        >
          <div className={classes.innerCircle}></div>
        </div>
        <div
          className={classes.sectionCircle}
          onClick={() => redirect('hobbies')}
          style={{ backgroundColor: currentSection === 2 ? palette.texts.highlight : undefined }}
        >
          <div className={classes.innerCircle}></div>
        </div>
      </div>
      <Grid container justify="center" alignItems="center" style={{ height: '100%' }} className={classes.aboutMeContainer}>
        {currentSection === 0 ? <MyResume /> : currentSection === 1 ? <MyExperiences /> : <MyHobbies />}
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(AboutMeContainer);
