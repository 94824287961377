import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { palette } from '../../../utils/toolbox/palette';
import { FaStackOverflow, FaGithub, FaLinkedin } from 'react-icons/fa';
import ResponsiveAdapter from '../../utils/ResponsiveAdapter';

const styles = (theme: Theme) =>
  createStyles({
      container: {
        padding: '3em 2em 2em',
        width: '100%',
        color: palette.texts.selected,
        [theme.breakpoints.down('md')]: {
          padding: '2em 3em 2em',
        },
        [theme.breakpoints.down('xs')]: {
          padding: 0,
          paddingBottom: theme.spacing(2),
        },
      },
      socialIcons: {
        margin: '0 0.5em',
        fontSize: '1.3rem',
        color: palette.links.default,
        transition: 'color .2s',
        '&:hover': {
          color: palette.links.hover,
        },
      },
      credits: {
        [theme.breakpoints.down('md')]: {
          fontSize: '0.9rem',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '0.8rem',
        },
      },
      socialIconsContainer: {
        marginBottom: '0.5em',
      },
  });

const Footer: React.FunctionComponent<WithStyles> = (props: WithStyles) => {
  const { classes } = props;

  return (
    <ResponsiveAdapter
      desktop={
        <Grid container direction='row' alignItems='center' justify='space-between' className={classes.container} />
      } mobile ={
        <Grid container direction='column-reverse' alignItems='center' justify='space-between' className={classes.container} />
      }>
        <Grid item>
          <Typography className={classes.credits}>© {new Date().getFullYear()} Julien Souki-Léon</Typography>
        </Grid>
        <Grid item className={classes.socialIconsContainer}>
          <a href='https://stackoverflow.com/users/6625159/souki' target='_blank' rel="noreferrer">
            <FaStackOverflow className={classes.socialIcons} />
          </a>
          <a href='https://github.com/juliensouki' target='_blank' rel="noreferrer">
            <FaGithub className={classes.socialIcons} />
          </a>
          <a href='https://www.linkedin.com/in/julien-souki-l%C3%A9on-91a1a8134/' target='_blank' rel="noreferrer">
            <FaLinkedin className={classes.socialIcons} />
          </a>
        </Grid>
    </ResponsiveAdapter>
  );
};

export default withStyles(styles)(Footer);
