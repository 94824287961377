export default {
  description: "Any questions? Want to chat? Feel free to send me a message and I will get back to you as soon as I can!",
  labels: {
    name: "Your name",
    email: "Your email",
    message: "Your message",
  },
  failureMessage: "Your message could not be sent... 😢",
  successMessage: "Your message was sent, I will answer asap 😁",
  formButton: "Send",
}