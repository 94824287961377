import fr from './fr';
import en from './en';
import { Languages, LanguagesManager } from '../../../config/languages';

export interface Definition {
  header: string;
  title1: string;
  title2: string;
  subtitle: string;
  link1: string;
  link2: string;
}

export default new LanguagesManager<Definition>({
  [Languages.FRENCH]: fr,
  [Languages.ENGLISH]: en,
});
