import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import HomeIcon from '@material-ui/icons/Home';
import LaptopIcon from '@material-ui/icons/Laptop';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import MailIcon from '@material-ui/icons/Mail';
import WorkIcon from '@material-ui/icons/Work';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { MenuItem } from '../../interfaces/interfaces';


export const menuItems: MenuItem[] = [{
  title: {
    en: 'home',
    fr: 'accueil',
  },
  icon: HomeIcon,
  url: '',
}, {
  title: {
    en: 'about me',
    fr: 'à propos de moi',
  },
  icon: AssignmentIndIcon,
  url: 'about-me',
  items: [{
    title: {
      en: 'skills',
      fr: 'Compétences',
    },
    icon: LaptopIcon,
    url: 'about-me/skills',
  }, {
    title: {
      en: 'experiences',
      fr: 'expériences',
    },
    icon: WorkIcon,
    url: 'about-me/experiences',  
  }, {
    title: {
      en: 'hobbies',
      fr: 'Loisirs',
    },
    icon: SportsSoccerIcon,
    url: 'about-me/hobbies',  
  }]
}, {
  title: {
    en: 'my projects',
    fr: 'Mes projets',
  },
  icon: AccountTreeIcon,
  url: 'projects',
}, {
  title: {
    en: 'contact me',
    fr: 'contact',
  },
  icon: MailIcon,
  url: 'contact',
}, {
  title: {
    fr: 'Mentions légales',
    en: 'Legal notice',
  },
  icon: BookmarkIcon,
  url: 'mentions-legales',
}];
