import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { CSSProperties } from '@material-ui/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Theme } from '@material-ui/core';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface Props extends WithStyles<typeof styles> {
  src: string;
  alt?: string;
  title?: string;
  className?: string;
  style?: CSSProperties;
}

const styles = (theme: Theme) =>
  createStyles({
    '@keyframes imageBackgroundPlaceholder': {
      '0%': {
        backgroundPosition: '0% 50%',
      },
      '50%': {
        backgroundPosition: '100% 50%',
      },
      '100%': {
        backgroundPosition: '0% 50%',
      },
    },
    imagePlaceholder: {
      background: 'linear-gradient(-45deg, #111111, #444444, #999999, #AAAAAA)',
      backgroundSize: '400% 400%',
      animation: '$imageBackgroundPlaceholder 15s ease infinite',
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
    },
  });


const ImageLoader: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {
  const { src, alt, title, className, style, classes } = props;

  return (
    <div className={classes.imagePlaceholder}>
      <LazyLoadImage
        className={className}
        height='100%'
        width='100%'
        alt={alt}
        style={style}
        title={title}
        src={src}
        effect='blur'
      />
    </div>
  );
};

export default withStyles(styles)(ImageLoader);
