import React, { useContext, useRef, useState, useEffect } from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { skills } from '../../data/skills';
import { AppContext } from '../../state/app.context';
import { palette } from '../../utils/toolbox/palette';
import StarIcon from '@material-ui/icons/Star';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useWindowDimensions from '../../utils/window-dimensions/use-window-dimensions';
import { useSpring, animated } from 'react-spring';
import texts from '../../lang/pages/about-me/skills';
import ImageLoader from '../../components/utils/image-loader';
import ResumeImage from '../../assets/resume.jpg';
import { translateTag } from '../../lang/utils/tags';

const commonArrowCSS = {
  position: "absolute" as any,
  zIndex: 3,
  top: "calc(50% - 9px)",
  backgroundColor: "#737373",
  borderRadius: '100%',
  padding: 2,
  height: 22,
  width: 22,
  color: "#fff",
  '&:hover': {
    cursor: "pointer",
  },
};

const mobileBreakpoint = 750;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    skillsMainContainer: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    nameText: {
      fontSize: 30,
      fontWeight: 'bold',
      color: '#fff',
      [theme.breakpoints.down(1466)]: {
        fontSize: 28,
      },
    },
    jobTitleText: {
      fontSize: 18,
      [theme.breakpoints.down(1466)]: {
        fontSize: 17,
      },
      color: palette.texts.light,
    },
    imageContainer: {
      position: 'relative',
      right: -theme.spacing(8),
      width: 250,
      height: 320,
      borderRadius: 6,
      backgroundColor: palette.texts.dark,
      marginTop: theme.spacing(6),
      zIndex: 2,
      [theme.breakpoints.down(1466)]: {
        width: 200,
        height: 260,
        marginTop: theme.spacing(3),
        zIndex: 1,
      },
      [theme.breakpoints.down("sm")]: {
        right: "unset",
        marginBottom: theme.spacing(3),
      },
    },
    leftContainer: {
      width: 'auto',
      paddingTop: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        width: 240,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        alignItems: "center",
      },
    },
    rightContainer: {
      backgroundColor: "#242424",
      borderRadius: 6,
      width: 'calc(100% - 412px)',
      marginLeft: theme.spacing(4),
      paddingLeft: theme.spacing(8),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        alignItems: "center",
        backgroundColor: "unset",
        padding: "unset",
        marginLeft: "unset",
      },
    },
    mePicture: {
      borderRadius: 'inherit',
      width: 'inherit',
      height: 'inherit',
      objectFit: 'cover',
    },
    sectionTitle: {
      color: palette.texts.highlight,
      textTransform: 'uppercase',
      marginTop: theme.spacing(2), 
      marginBottom: theme.spacing(2), 
      fontWeight: 'bold',
      fontSize: 14,
      '&:not(:first-child)': {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.down(1466)]: {
        fontSize: 13,
        marginTop: theme.spacing(1), 
        marginBottom: theme.spacing(1), 
        },
    },
    descriptionText: {
      color: palette.texts.light,
      marginTop: theme.spacing(2),
      [theme.breakpoints.down(1466)]: {
        fontSize: 14,
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "justify",
      },
    },
    skillsContainer: {
      flexWrap: "nowrap",
      overflowX: 'auto',
      scrollbarHeight: "none",
      "-ms-overflow-style": "none",
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
      },
      maskImage: "linear-gradient(to right, black 85%, transparent 100%)",
      scrollBehavior: 'smooth',
      [theme.breakpoints.up(1466)]: {
        paddingLeft: 9,
      },
      [theme.breakpoints.down(mobileBreakpoint)]: {
        maskImage: "unset",
        overflowX: "hidden",
        flexWrap: "wrap",
        paddingTop: 5,
      },
    },
    skillContainer: {
      position: "relative",
      height: 130,
      width: 120,
      backgroundColor: '#3E3E3E',
      borderRadius: 6,
      marginRight: 16,
      padding: 8,
      paddingBottom: 16,
      marginTop: theme.spacing(2),
      flexShrink: 0,
      [theme.breakpoints.down(mobileBreakpoint)]: {
        height: 100,
        width: `calc(33% - 3px)`,
        margin: 6,
        marginLeft: 0,
        marginTop: 0,
        justifyContent: "space-evenly",
        '&:nth-child(3n+3)': {
          marginRight: 0,
        },
      },
      [theme.breakpoints.down(375)]: {
        width: `calc(50% - 3px)`,
        '&:nth-child(3n+3)': {
          marginRight: 6,
        },
        '&:nth-child(even)': {
          marginRight: 0,
        },
      },
    },
    skillTitle: {
      color: "#fff",
      textTransform: 'capitalize',
      marginTop: 10,
      fontSize: 14,
      [theme.breakpoints.down(1466)]: {
        fontSize: 13,
      },
      [theme.breakpoints.down(mobileBreakpoint)]: {
        fontSize: 12,
        marginTop: 0,
      },
    },
    skillLogo: {
      width: 70,
      [theme.breakpoints.down(mobileBreakpoint)]: {
        width: 50,
        marginTop: 10,
      },
    },
    favoriteSkill: {
      position: "absolute",
      left: "calc(50% - 9px)",
      top: -9,
      backgroundColor: "#737373",
      borderRadius: '100%',
      padding: 2,
      height: 16,
      color: "yellow",
      [theme.breakpoints.down(mobileBreakpoint)]: {
        top: 5,
        left: 5,
      },
    },
    rightArrow: {
      ...commonArrowCSS,
      right: 9,
    },
    leftArrow: {
      ...commonArrowCSS,
      transform: "rotate(180deg)",
      zIndex: 10,
      left: 0,
    },
    nameContainer: {
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    skillAnimationWrapper: {
      [theme.breakpoints.down(mobileBreakpoint)]: {
        width: '100%',
      },
    },
  });

const MySkills: React.FunctionComponent<WithStyles> = (props: React.PropsWithChildren<WithStyles>) => {
  const { classes } = props;
  const { language } = useContext(AppContext);
  const ref = useRef(null);
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState<boolean>();
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [maxScroll, setMaxScroll] = useState<number>(0);
  const T = texts.current(language);

  const handleClickArrow = (direction: "left" | "right") => {
    if (ref && ref.current) {
      if (direction === "left") {
        (ref.current as any).scrollLeft -= 100;
      } else {
        (ref.current as any).scrollLeft += 100;
      }
    }
  };

  useEffect(() => {
    if (ref && ref.current) {
      setMaxScroll((ref.current as any).scrollWidth - (ref.current as any).clientWidth);
      setScrollPosition((ref.current as any).scrollLeft);
      (ref.current as any).addEventListener("scroll", () => ref && ref.current && setScrollPosition((ref.current as any).scrollLeft));
    
      return () => {
        if (ref && ref.current) {
          (ref.current as any).removeEventListener("scroll", () => ref && ref.current && setScrollPosition((ref.current as any).scrollLeft));
        }
      };
    }
  }, [scrollPosition]);

  useEffect(() => {
    setIsMobile(width < mobileBreakpoint);
  }, [width]);

  const imageAnimation = useSpring({
    config: { duration: 400 },
  });

  const rightContainerAnimation = useSpring({
    from: { opacity: 0, x: 40 }, 
    to: { opacity: 1, x: 0 },
    config: { duration: 400 },
  });

  const fadeInAnimation = useSpring({
    from: { opacity: 0 }, 
    to: { opacity: 1 },
    config: { duration: 400 },
  });

  const skillsAnimations = skills.map((_skill, index) => useSpring({
    from: { opacity: 0 }, 
    to: { opacity: 1 },
    config: { duration: 400 },
    delay: index * 100,
  }));

  return (
    <Container maxWidth="lg" style={{ height: '100%' }} className={classes.root}>
      <Grid container direction='row' className={classes.skillsMainContainer}>
        <Grid container direction="column" className={classes.leftContainer} alignItems="flex-end">
          <Grid container item direction="column" alignItems="flex-end" className={classes.nameContainer}>
            <animated.div style={fadeInAnimation}>
              <Typography className={classes.nameText}>Julien Souki-Léon</Typography>
              <Typography className={classes.jobTitleText}>{T.jobTitle}</Typography>
            </animated.div>
          </Grid>
          <animated.div style={imageAnimation}>
            <div className={classes.imageContainer}>
              <ImageLoader
                className={classes.mePicture}
                style={{ borderRadius: 6 }}
                src={ResumeImage}
                alt='Julien Souki-Léon, a fullstack software engineer.'
                title='Fullstack software engineer'
              />
            </div>
          </animated.div>
        </Grid>
        <animated.div style={rightContainerAnimation} className={classes.rightContainer}>
          <Typography className={classes.sectionTitle}>{T.aboutMeTitle}</Typography>
          <Typography className={classes.descriptionText}>{T.aboutMe1}</Typography>
          <Typography className={classes.descriptionText}>{T.aboutMe2}</Typography>
          <Typography className={classes.sectionTitle}>{T.skillsTitle}</Typography>
          <div style={{ position: 'relative'}}>
            <Grid container direction="row" className={classes.skillsContainer} ref={ref}>
              {!isMobile && ref && ref.current && (ref.current as any).scrollLeft > 0 && (
                <div className={classes.leftArrow} onClick={() => handleClickArrow("left")}>
                  <ChevronRightIcon style={{ fontSize: 22 }}/>
                </div>
              )}
              {skills.filter(skill => skill.type === 'hard' && !skill.excludeOnMySkills).map((skill, index) => 
                <Grid
                  item
                  className={classes.skillContainer}
                  container
                  alignItems="center"
                  justify="flex-end"
                  direction="column"
                  key={`${skill.name}-${index}`}
                >
                  <animated.div style={{ ...skillsAnimations[index], textAlign: 'center' }} key={`animation-skill-${index}`}>
                    {skill.isFav && (
                      <div className={classes.favoriteSkill}>
                        <StarIcon style={{ fontSize: 16 }} />
                      </div>
                    )}
                    <img
                      className={classes.skillLogo}
                      src={skill.image}
                      style={{
                        ...skill.overrideStyle,
                        position: 'relative',
                        top: width > 750 ? skill.overrideStyle?.top : undefined, 
                        width: width <= 375
                          ? skill.overrideStyle?.xsWidth
                          : width <= 750
                            ? skill.overrideStyle?.sWidth
                            : skill.overrideStyle?.width,
                      }}
                    />
                    <Typography className={classes.skillTitle}>{translateTag(skill.name, language)}</Typography>
                  </animated.div>
                </Grid>
              )}
            </Grid>
            {!isMobile && scrollPosition < maxScroll && (
              <div className={classes.rightArrow} onClick={() => handleClickArrow("right")}>
                <ChevronRightIcon style={{ fontSize: 22 }}/>
              </div>
            )}
          </div>
        </animated.div>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(MySkills);
