export enum Languages {
  FRENCH = 'fr',
  ENGLISH = 'en',
}

type LanguageConfiguration = {
  shortName: Languages;
  default: boolean;
};

export const supportedLanguages: LanguageConfiguration[] = [{
  shortName: Languages.ENGLISH,
  default: true,
}, {
  shortName: Languages.FRENCH,
  default: false,
}];

export const defaultLanguage: Languages = (supportedLanguages.filter(lang => lang.default)[0]).shortName;

export class LanguagesManager<T> {
  translations: Record<Languages, T>;

  constructor(translations: Record<Languages, T>) {
    this.translations = translations;
  }

  current = (language: Languages): T => this.translations[language];
}