import React, { useContext, useState } from 'react';
import { TextField, Button, Container, Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import texts from '../lang/pages/contact';
import { AppContext } from '../state/app.context';
import { palette } from '../utils/toolbox/palette';
import emailjs from 'emailjs-com';
import { ContactErrors } from '../interfaces/interfaces';
import { toast, ToastContainer } from 'react-toastify';
import ContactImage from '../assets/contact.jpg';
import ImageLoader from '../components/utils/image-loader';

import 'react-toastify/dist/ReactToastify.css';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: palette.texts.light,
      [theme.breakpoints.down(1466)]: {
        maxWidth: 1100,
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    formContainer: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: 'unset',
        paddingTop: theme.spacing(2),
      },
    },
    imageContainer: {
      padding: theme.spacing(2),
      maxHeight: 600,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    image: {
      objectFit: 'cover',
      height: '100%',
      maxWidth: '100%',
      backgroundColor: "#232323",
      boxShadow: '5px 5px 17px 3px rgba(0,0,0,0.32)',
    },
    input: {
      backgroundColor: "#232323",
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.texts.light,
      },
      '& .MuiInputLabel-outlined': {
        color: palette.texts.light,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.texts.light,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.texts.light,
      },
      '& .MuiOutlinedInput-input': {
        padding: '10.5px 14px',
      },
      '& .MuiOutlinedInput-multiline': {
        padding: 'unset',
      },
    },
    error: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'red',
      },
    },
    sendButton: {
      backgroundColor: palette.texts.highlight,
      color: "#fff",
      width: 150,
      float: 'right',
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
        float: 'unset',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    sendButtonContainer: {
      textAlign: 'right',
      width: '100%',
      marginTop: theme.spacing(2),
    },
    description: {
      fontSize: 18,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down(1466)]: {
        fontSize: 16,
      },
    },
    label: {
      fontSize: 15,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down(1466)]: {
        fontSize: 13,
      },
    },
    inputText: {
      color: palette.texts.light,
    }
  });

const Contact: React.FunctionComponent<WithStyles> = (props: React.PropsWithChildren<WithStyles>) => {
  const { classes } = props;
  const { language } = useContext(AppContext);
  const T = texts.current(language);

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [errors, setErrors] = useState<ContactErrors>({
    name: false,
    email: false,
    message: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkName = (): boolean => {
    return name.length > 0;
  }

  const checkEmail = (): boolean => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  }

  const checkMessage = (): boolean => {
    return message.length > 0;
  }

  const errorValidation = (): boolean => {
    const isNameValid = checkName();
    const isEmailValid = checkEmail();
    const isMessageValid = checkMessage();

    setErrors({
      name: !isNameValid,
      email: !isEmailValid,
      message: !isMessageValid,
    });
    return (isNameValid && isEmailValid && isMessageValid);
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  const showNotification = (success: boolean): void => {
    toast(success ? T.successMessage : T.failureMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      style: { backgroundColor: palette.texts.dark },
      theme: "dark",
      type: success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
    });
  };

  const sendEmail = () => {
    if (errorValidation()) {
      setIsLoading(true);
      emailjs.send(
        process.env.EMAILJS_SERVICE_ID as string,
        process.env.EMAILJS_TEMPLATE_ID as string,
        { from_name: name, from_email: email, message },
      )
        .then(() => {
          showNotification(true);
          clearForm();
          setIsLoading(false);
        }, () => {
          showNotification(false);
          setIsLoading(false);
        });
    }
  };

  return (
    <Grid container direction="column" alignItems="center" justify="center" style={{ height: '100%' }}>
      <Container maxWidth="lg" className={classes.root}>
        <ToastContainer />
        <Grid container direction="row">
          <Grid item md={4} lg={3} className={classes.imageContainer}>
            <ImageLoader
              src={ContactImage}
              className={classes.image}
              alt='A laptop in the dark'
              title='A laptop'
            />
          </Grid>
          <Grid item container direction="column" md={8} lg={9} className={classes.formContainer}>
            <Typography className={classes.description}>{T.description}</Typography>
            <Typography className={classes.label}>{T.labels.name} *</Typography>
            <TextField
              value={name}
              onChange={event => setName(event.target.value)}
              type="text"
              className={`${errors.name ? classes.error : ''} ${classes.input}`}
              InputProps={{
                autoComplete: 'none',
                className: classes.inputText,
              }}
              variant="outlined"
              onFocus={() => setErrors({ ...errors, name: false })}
            ></TextField>
            <Typography className={classes.label}>{T.labels.email} *</Typography>
            <TextField
              value={email}
              onChange={event => setEmail(event.target.value)}
              type="email"
              variant="outlined"
              InputProps={{
                autoComplete: 'none',
                className: classes.inputText,
              }}
              className={`${errors.email ? classes.error : ''} ${classes.input}`}
              onFocus={() => setErrors({ ...errors, email: false })}
            ></TextField>
            <Typography className={classes.label}>{T.labels.message} *</Typography>
            <TextField
              value={message}
              onChange={event => setMessage(event.target.value)}
              type="text"
              multiline
              rows={6}
              style={{ padding: 0 }}
              className={`${errors.message ? classes.error : ''} ${classes.input}`}
              InputProps={{
                autoComplete: "none",
                className: classes.inputText,
              }}
              variant="outlined"
              onFocus={() => setErrors({ ...errors, message: false })}
            ></TextField>
            <br />
            <div className={classes.sendButtonContainer}>
              <Button disabled={isLoading} onClick={sendEmail} className={classes.sendButton}>{T.formButton}</Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default withStyles(styles)(Contact);
