import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = () =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#363636',
      color: '#fff',
    },
    input: {
      flex: 1,
      fontSize: 12,
      color: '#fff',
    },
    iconButton: {
      fontSize: 10,
      padding: 5,
      color: '#fff',
    },
    inputPlaceholder: {
      '&::placeholder': {
        color: '#c9c9c9',
      },
    },
  });

interface Props extends WithStyles {
  placeholder?: string;
  style?: any;
  icon?: JSX.Element;
  closeIcon?: boolean;
  value: string;
  onChange: (value: string) => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
}

const Input: React.FunctionComponent<Props> = (props: Props) => {
  const { classes, icon, style, placeholder, closeIcon, value, onChange, onKeyPress } = props;
  const Icon = icon;

  return (
    <Paper component="form" className={classes.root} style={style}>
      {Icon && (
        <IconButton className={classes.iconButton} aria-label="menu">
          {Icon}
        </IconButton>
      )}
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': placeholder, className: classes.inputPlaceholder }}
        value={value}
        onChange={e => onChange(e.target.value)}
        onKeyPress={onKeyPress}
      />
      {closeIcon && value.length > 0 && (
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
          onClick={() => onChange('')}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default withStyles(styles)(Input);
