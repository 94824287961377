import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../state/app.context';
import { Grid, Typography } from '@material-ui/core';
import { palette } from '../../../utils/toolbox/palette';
import { Languages, supportedLanguages } from '../../../config/languages';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { getLanguageFromURL } from '../../../lang/utils/URL';

const styles = (theme: Theme) =>
  createStyles({
    languageItem: {
      color: palette.texts.light,
      textTransform: 'capitalize',
      lineHeight: '30px',
      width: 30,
      margin: 3,
      transition: 'color, border-color .3s',
      textAlign: 'center',
      border: `1px solid transparent`,
      '&:hover': {
        cursor: 'pointer',
        color: palette.texts.selected,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '0.9rem',
        lineHeight: '25px',
      },
    },
    selectedLanguage: {
      display: 'inline-block',
      borderColor: palette.texts.selected,
      color: palette.texts.selected,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        width: '25px',
      },
    },
  });

const LanguageSwitch: React.FunctionComponent<WithStyles> = (props: WithStyles) => {
  const { language, setLanguage } = useContext(AppContext);
  const { classes } = props;
  const history = useHistory();
  const location = useLocation();

  const handleLanguagechange = (newLanguage: Languages) => {
    history.replace(`${location.pathname.replace(language, newLanguage)}${history.location.search}`);
    setLanguage(newLanguage);
  };

  useEffect(() => {
    const currentLanguage = getLanguageFromURL(history.location.pathname);
    setLanguage(currentLanguage);
  }, [history.location.pathname]);

  return (
      <Grid container direction='row'>
        {supportedLanguages.map(lang =>
          <Grid item key={lang.shortName} onClick={() => handleLanguagechange(lang.shortName)}>
            <Typography
              className={`${classes.languageItem} ${lang.shortName === language ? classes.selectedLanguage : ''}`}
            >
              {lang.shortName}
            </Typography>
          </Grid>
        )}
      </Grid>
  );
};

export default withStyles(styles)(LanguageSwitch);
