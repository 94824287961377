import fr from './fr';
import en from './en';
import { Languages, LanguagesManager } from '../../../config/languages';

export interface Definition {
  description: string;
  labels: {
    name: string;
    email: string;
    message: string;
  };
  failureMessage: string;
  successMessage: string;
  formButton: string;
}

export default new LanguagesManager<Definition>({
  [Languages.FRENCH]: fr,
  [Languages.ENGLISH]: en,
});
