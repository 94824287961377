import React, { useState, useMemo, useCallback, useContext } from 'react';
import { debounce } from "lodash";
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Grid, IconButton, CircularProgress } from '@material-ui/core';
import Input from '../../form-controls/Input';
import { palette } from '../../../utils/toolbox/palette';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { menuItems } from '../../../utils/menu/menu-items';
import { MenuItem } from '../../../interfaces/interfaces';
import SearchItem from './SearchItem';
import UIMenuItem from './UIMenuItem';
import { AppContext } from '../../../state/app.context';
import { Project } from '../../../interfaces/interfaces';
import NoResultsLogo from '../../../assets/no_results.png';
import { searchProjects } from '../../../utils/search/search-utils';
import texts from '../../../lang/components/layout/left-menu';

const styles = (theme: Theme) =>
  createStyles({
    menuTitleContainer: {
      textAlign: 'right',
    },
    name: {
      fontSize: 20,
      fontWeight: 500,
    },
    portfolio: {
      color: palette.texts.highlight,      
    },
    mainContainer: {
      padding: '2em 2em 2em 2em',
      [theme.breakpoints.down('xs')]: {
        padding: '2em 1em 2em 1em',
      },
    },
    label: {
      textTransform: 'capitalize',
      fontSize: 13,
    },
    topContainer: {
      padding: '2em 2em 1em 1em',
      [theme.breakpoints.down('xs')]: {
        padding: '1em 1em 1em 1em',
      },
    },
    navbarIconContainer: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
      },
    },
    navbarIcon: {
      color: '#fff',
    },
    noResultImage: {
      height: 100,
      width: 'auto',
      marginBottom: theme.spacing(2),
    },
    loaderContainer: {
      marginTop: theme.spacing(2),
    },
    loader: {
      color: palette.texts.highlight,
    }
  });

const flattenMenuItems = (): MenuItem[] => {
  const flatten: MenuItem[] = [];
  for (const item of menuItems) {
    flatten.push(item);
    if (item.items && item.items.length > 0) {
      flatten.push(...item.items);
    }
  }
  return flatten;
};

interface Props extends WithStyles {
  openOrCloseMenu: () => void;
}

const LeftMenu: React.FunctionComponent<Props> = (props: Props) => {
  const { language } = useContext(AppContext);
  const { classes, openOrCloseMenu } = props;
  const [search, setSearch] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Project[]>([]);
  const T = texts.current(language);

  const menuItemsFlatten = useMemo(() => flattenMenuItems(), [menuItems]);

  const getSearchResults = (value: string) => {
    if (value.length < 2) {
      setSearchResults([]);
    } else {
      const results = searchProjects(value, language);
      setSearchResults(results);
    }
    setIsSearching(false);
  };

  const debounceHandler = useCallback(debounce(getSearchResults, 500), []);
  const handleSearchChange = (value: string) => {
    setSearch(value);
    setIsSearching(true);
    setSearchResults([]);
    debounceHandler(value);
  };

  return (
    <div>
      <Grid container direction='row' alignItems='flex-start' justify='space-between' className={classes.topContainer}>
        <Grid item>
          <IconButton
            className={classes.navbarIconContainer}
            onClick={openOrCloseMenu}
          >
            <CloseIcon className={classes.navbarIcon} />
          </IconButton>
        </Grid>
        <div className={classes.menuTitleContainer}>
          <Typography className={classes.name}>Julien Souki-Léon</Typography>
          <Typography className={classes.portfolio}>Portfolio</Typography>
        </div>
      </Grid>

      <div className={classes.mainContainer}>
        <Typography className={classes.label}>{T.search}</Typography>
        <Input
          closeIcon
          style={{ margin: '1em 0 2em 0' }}
          placeholder={T.searchPlaceholder}
          icon={<SearchIcon/>}
          onChange={handleSearchChange}
          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          value={search}
        ></Input>
        {search.length < 3
          ? (
            <>
              <Typography className={classes.label}>{T.navigation}</Typography>
              {menuItemsFlatten.map(item =>
                <UIMenuItem
                  key={item.title[language]}
                  closeMenu={openOrCloseMenu}
                  item={item}>
                </UIMenuItem>
              )}    
            </>
          ) : (
            <>
              {searchResults.map((project, index) =>
                <SearchItem key={project.name} index={index} project={project} closeMenu={openOrCloseMenu} />
              )}
              {isSearching && (
                <Grid container direction='row' justify='center' alignItems='center' className={classes.loaderContainer}>
                  <CircularProgress className={classes.loader} />
                </Grid>
              )}
              {!isSearching && searchResults.length === 0 && (
                <Grid container direction='column' justify='center' alignItems='center'>
                  <img className={classes.noResultImage} src={NoResultsLogo} />
                  <Typography style={{ fontSize: 14 }}>{T.noResult}</Typography>
                </Grid>
              )}
            </>
          )
        }
      </div>
    </div>
  );
};

export default withStyles(styles)(LeftMenu);
