import { Project, ProjectCategory, Tag } from "../../interfaces/interfaces";
import { projects } from "../../data/projects";
import { uniq } from "lodash";
import { get as levenshtein } from 'fast-levenshtein';
import { Languages } from "../../config/languages";
import { translateCategory } from "../../lang/utils/categories";
import { translateTag } from "../../lang/utils/tags";

const searchPerTags = (value: string, language: Languages) => projects.filter(project => project.tags.map(t => translateTag(t, language)).find(tag => levenshtein(tag.toLowerCase(), value.toLowerCase()) <= 2 || value.length >= 4 && tag.toLowerCase().includes(value.toLowerCase())));
const searchPerName = (value: string) => projects.filter(project => project.name.toLowerCase().includes(value.toLowerCase()) || levenshtein(project.name.toLowerCase(), value.toLowerCase()) <= 2);
const searchPerContext = (value: string, language: Languages) => projects.filter(project => value.length >= 5 && project.context[language].toLowerCase().includes(value.toLowerCase()));
const searchPerDescription = (value: string, language: Languages) => projects.filter(project => value.length >= 5 && project.description[language].toLowerCase().includes(value.toLowerCase()));
const searchPerType = (value: string, language: Languages) => projects.filter(project => levenshtein(translateCategory(project.type, language), value.toLowerCase()) <= 2);

export const searchProjects = (value: string, language: Languages): Project[] => {
  const matchPerTags = searchPerTags(value, language);
  const matchPerName = searchPerName(value);
  const matchPerContext = searchPerContext(value, language);
  const matchPerDescription = searchPerDescription(value, language);
  const matchPerType = searchPerType(value, language);
  return (uniq([...matchPerTags, ...matchPerName, ...matchPerContext, ...matchPerDescription, ...matchPerType]));
};

export const searchProjectsByCategory = (projects: Project[], category: ProjectCategory, tags: string[], language: Languages): Project[] => {
  const projectsWithType = category === 'all' ? projects : searchPerType(translateCategory(category, language), language);
  return projectsWithType.filter(project => tags.every(tag => project.tags.map(t => translateTag(t, language)).includes(tag)));
};