const DARK_GREY = '#303030';
const LIGHT_GREY = '#6E6E6E';
const GREEN = '#23b794';
const WHITE = '#fff';
const BLACK_GRADIENT = 'linear-gradient(to bottom, rgba(20,20,20,1) 0%, rgba(28,28,28,1) 100%)';

type Palette = {
  background: string;
  texts: {
    dark: string;
    light: string;
    highlight: string;
    selected: string;
  },
  links: {
    default: string;
    hover: string;
  },
  interactive: {
    default: string;
    hover: string;
  },
};

export const palette: Palette = {
  background: BLACK_GRADIENT,
  texts: {
    dark: DARK_GREY,
    light: LIGHT_GREY,
    highlight: GREEN,
    selected: WHITE,
  },
  links: {
    default: WHITE,
    hover: GREEN,
  },
  interactive: {
    default: LIGHT_GREY,
    hover: GREEN,
  },
};
