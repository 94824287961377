import React, { useContext, useState, useEffect } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { useHistory, useParams, useLocation, Redirect } from "react-router-dom";
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { palette } from '../utils/toolbox/palette';
import texts from '../lang/pages/specific-project';
import { AppContext } from '../state/app.context';
import ProjectDetails from '../components/projects/project-details';
import { projects } from '../data/projects';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Project } from '../interfaces/interfaces';
import { URLEncode } from '../utils/URL/url-utils';
import Spotlight from "spotlight.js/src/js/spotlight.js";
import "spotlight.js/src/css/spotlight.css";
import { orderImageForGallery } from '../utils/images/images-utils';
import useWindowDimensions from '../utils/window-dimensions/use-window-dimensions';
import ImageLoader from '../components/utils/image-loader';

const imagesCSS = {
  display: 'flex' as any,
  flexDirection: 'column' as any,
  backgroundColor: palette.texts.dark,
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
}

const MOBILE_WIDTH = 1000;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1200,
      margin: "auto",
      justifyContent: 'space-between',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-evenly',
      },
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        flexDirection: 'column',
      },
    },
    mainPicture: {
      ...imagesCSS,
      width: 350,
      height: 350,
      marginBottom: 5,
      [theme.breakpoints.down('md')]: {
        width: 300,
        height: 300,
      },
    },
    smallerPictures: {
      ...imagesCSS,
      width: 110,
      height: 110,
      margin: 5,
      [theme.breakpoints.down('md')]: {
        width: 95,
        height: 95,
      },
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    backButtonText: {
      [theme.breakpoints.up("md")]: {
        opacity: 0,
        transform: 'translateX(-10px)',
      },
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        fontSize: 14,
      },
      transition: 'all .3s',
      color: "#fff",
    },
    backButton: {
      backgroundColor: palette.texts.dark,
      color: palette.texts.light,
      borderRadius: theme.spacing(3),
      marginRight: theme.spacing(1.5),
      textTransform: 'capitalize',
      minWidth: "unset",
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        padding: '2px 4px',
      },
      '&:hover': {
        backgroundColor: '#393939',
      },
    },
    backButtonContainer: {
      marginBottom: theme.spacing(5),
      '&:hover $backButtonText': {
        [theme.breakpoints.up("md")]: {
          opacity: 1,
          transform: 'translateX(0)',
        },
      },
    },
    image: {
      objectFit: 'cover',
      height: '100%',
      maxWidth: '100%',
    },
    mainImageContainer: {
      width: 350,
      [theme.breakpoints.down('md')]: {
        width: 300,
      },
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        width: 'unset',
      },
    },
    projectDetailsContainer: {
      maxWidth: 700,
      [theme.breakpoints.down('md')]: {
        maxWidth: 550,
      },
    },
    smallImagesContainer: {
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        marginTop: theme.spacing(3),
      },
    },
    logoContainer: {
      width: 350,
      [theme.breakpoints.down('md')]: {
        width: 300,
      },
      [theme.breakpoints.down(MOBILE_WIDTH)]: {
        width: '100%',
        maxWidth: 550,
      },
    },
  });

const Home: React.FunctionComponent<WithStyles> = (props: React.PropsWithChildren<WithStyles>) => {
  const { classes } = props;
  const { language } = useContext(AppContext);
  const T = texts.current(language);
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ projectId: string }>();
  const [selectedProject, setSelectedProject] = useState<Project | undefined | null>(undefined);
  const [isMobile, setIsMobile] = useState<boolean>();
  const { width } = useWindowDimensions();

  useEffect(() => {
    setIsMobile(width < MOBILE_WIDTH);
  }, [width]);

  const handleBackButtonClick = () => {
    history.goBack();
  };

  const handleImageClick = (images: string[], startImageIndex: number): void => {
    const orderedImages = orderImageForGallery(images, startImageIndex);
    Spotlight.show(orderedImages.map(image => ({ src: image })));
  };

  useEffect(() => {
    const selectedProjectName = params.projectId;
    let isLoaded = false;
    projects.map(project => {
      if (selectedProjectName === URLEncode(project.name)) {
        setSelectedProject(project);
        isLoaded = true;
      }
    });
    if (!isLoaded) {
      setSelectedProject(null);
    }
    Spotlight.init();
  }, [location.pathname]);

  if (selectedProject) {
    return (
      <Grid container alignItems='center' className={classes.root}>
        <Grid item className={classes.projectDetailsContainer}>
          <Grid item container direction="row" alignItems='center' className={classes.backButtonContainer}>
            <Button onClick={handleBackButtonClick} className={classes.backButton}>
              <ChevronLeftIcon />
            </Button>
            <Typography className={classes.backButtonText}>{T.backToProjects}</Typography>
          </Grid>
          <ProjectDetails project={selectedProject} />
        </Grid>
        <Grid
          item
          container
          direction='column'
          className={selectedProject.images.length > 0 ? classes.mainImageContainer : classes.logoContainer}
          alignItems='center'
        >
          {selectedProject.images.length >= 1 && !isMobile && (
            <div onClick={() => handleImageClick(selectedProject.images, 0)} className={classes.mainPicture}>
              <ImageLoader className={classes.image} src={selectedProject.images[0]} />
            </div>
          )}
          <Grid container direction='row' className={classes.smallImagesContainer}>
            {selectedProject.images.map((image, index) => (index > 0 || isMobile) && (
              <div
                onClick={() => handleImageClick(selectedProject.images, index)}
                className={classes.smallerPictures}
                key={`small-image-${index}`}
              >
                <ImageLoader className={classes.image} src={image} />
              </div>
            ))}
          </Grid>
          {(!selectedProject.images || selectedProject.images.length === 0) && (
            <Grid
              container
              direction='column'
              alignItems='center'
              justify='center'
              className={classes.mainPicture}
              style={{
                backgroundColor: selectedProject.projectsPage.backgroundColor,
                width: isMobile ? '100%' : undefined,
                height: isMobile ? 150 : undefined,
              }}
            >
              <img
                src={selectedProject.projectsPage.logo}
                className={classes.projectLogo}
                style={{
                  width: isMobile ? selectedProject.projectsPage.overrideWidthMobile : selectedProject.projectsPage.overrideWidth,
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  } else if (selectedProject === null) {
    return <Redirect to={`/${language}`} />;
  } else {
    return <></>;
  }
};

export default withStyles(styles)(Home);
