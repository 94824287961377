import React, { useContext, useEffect, useState } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { Theme, createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import texts from "../../lang/pages/home";
import { AppContext } from "../../state/app.context";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Project } from "../../interfaces/interfaces";
import { palette } from "../../utils/toolbox/palette";
import LinkIcon from '@material-ui/icons/Link';
import GitHubIcon from '@material-ui/icons/GitHub';
import { Redirect, useHistory } from "react-router";
import useWindowDimensions from '../../utils/window-dimensions/use-window-dimensions';
import { translateTag } from '../../lang/utils/tags';
import { Languages } from "../../config/languages";

const styles = (theme: Theme) =>
  createStyles({
    projectHeader: {
      color: "#fff",
    },
    projectName: {
      fontSize: 25,
      fontWeight: "bold",
      color: palette.texts.highlight,
      [theme.breakpoints.down("md")]: {
        fontSize: 22,
      },
    },
    projectDates: {
      fontSize: 14,
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
    calendarIcon: {
      position: "relative",
      top: 2,
      marginRight: theme.spacing(1),
      fontSize: 17,
      [theme.breakpoints.down("md")]: {
        fontSize: 15,
      },
    },
    projectTag: {
      fontSize: 13,
      padding: "5px 10px",
      borderRadius: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: palette.texts.dark,
      color: "#cccccc",
      transition: "background-color .3s",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#555555",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
        padding: "5px 8px",
        borderRadius: theme.spacing(1.7),
      },
    },
    tagsContainer: {
      marginTop: theme.spacing(2),
    },
    contextContainer: {
      marginTop: theme.spacing(3),
      color: "#fff",
      width: "100%",
    },
    horizontalLine: {
      display: 'inline-block',
      width: '45px',
      height: 2,
      border: 'none',
      backgroundColor: palette.texts.dark,
    },
    projectDescription: {
      marginTop: theme.spacing(4),
      color: palette.texts.light,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
    projectContext: {
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
    link: {
      marginLeft: theme.spacing(1),
      color: '#5280F2',
      fontSize: 13,
    },
    linkIcon: {
      '&:not(:first-child)': {
        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(2),
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: 30,
      },
      color: palette.texts.light,
    },
    linksContainer: {
      marginTop: theme.spacing(2),
      paddingLeft: 69,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    mobileBreakPoint: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  });

interface Props extends React.PropsWithChildren<WithStyles> {
  project: Project,
  addBackButton?: boolean;
}
  
const ProjectDetails: React.FunctionComponent<Props> = (props: Props) => {
  const { classes, project, addBackButton } = props;
  const { language } = useContext(AppContext);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState<boolean>();

  const redirectToMyProjects = (tag: string): void => {
    if (!isMobile) {
      history.push(`/${language}/projects${window.location.search}${window.location.search === '' ? '?' : '&'}filter=${tag}`);
    }
  };

  useEffect(() => {
    setIsMobile(width < 600);
  }, [width]);
  
  return (
    <Grid container direction="column">
      {addBackButton && (
        <Button
          onClick={() => <Redirect to='/projects' />}
          style={{
            width: 100,
            backgroundColor: palette.texts.dark,
            marginBottom: 20,
          }}>
            Back
          </Button>
      )}
      <Grid item container direction="row" alignItems="center" justify="space-between" className={classes.projectHeader}>
        <Typography className={classes.projectName}>{project.name}</Typography>
        <Grid item container direction="row" style={{ width: "auto" }}>
          <CalendarTodayIcon className={classes.calendarIcon} />
          <Typography className={classes.projectDates}>{project.dateStart[language]} - {project.dateEnd[language]}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.contextContainer}>
        <Grid container item direction="column" justify="center" style={{ width: "auto" }}>
          <hr className={classes.horizontalLine}/>
        </Grid>
        <Grid item container direction="column" style={{ width: "calc(100% - 69px)", marginLeft: 24 }}>
          <Typography className={classes.projectContext}>{project.context[language]}</Typography>
        </Grid>
        <Grid container direction="row" alignItems='center' className={classes.linksContainer}>
          {project.website && (
            <>
              <LinkIcon className={classes.linkIcon} />
              <a href={project.website.includes('http') ? project.website :  `https://www.${project.website}`} target='_blank' rel='noreferrer'>
                <Typography className={classes.link}>{project.website}</Typography>
              </a>
            </>
          )}
          <div className={classes.mobileBreakPoint}></div>
          {project.github && (
            <>
              <GitHubIcon className={classes.linkIcon} style={{ fontSize: 17 }} />
              <a href={`https://www.${project.github}`} target='_blank' rel='noreferrer'>
                <Typography className={classes.link}>{project.github}</Typography>
              </a>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.tagsContainer}>
        {project.tags.map((tag) => (
          <Typography
            onClick={() => redirectToMyProjects(translateTag(tag, Languages.ENGLISH))} //Always use english for query parameters 
            className={classes.projectTag}
            key={tag}
          >
            {translateTag(tag, language)}
          </Typography>
        ))}
      </Grid>
      <Typography
        style={{ whiteSpace: "pre-line" }}
        className={classes.projectDescription}
      >
        {project.description[language]}
      </Typography>
    </Grid>
  );
};

export default withStyles(styles)(ProjectDetails);
