import React, { useContext, useRef } from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import { Theme, createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { projects } from '../../data/projects';
import { AppContext } from "../../state/app.context";
import { palette } from "../../utils/toolbox/palette";
import useWindowDimensions from "../../utils/window-dimensions/use-window-dimensions";
import { useSpring, animated } from 'react-spring';
import { ProjectType } from "../../interfaces/interfaces";

const commonRoundCSS = {
  position: "relative" as any,
  borderRadius: '100%',
  height: 13,
  width: 13,
  margin: 8,
};

const commonProjectContainerCSS = (theme: Theme) => ({
  position: "relative" as any,
  width: 200,
  backgroundColor: "#272727",
  padding: 8,
  borderRadius: 5,
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: "calc(100vh - 3em - 48px - 5em - 33px - 24px)",
      [theme.breakpoints.down("md")]: {
        alignItems: "flex-end",
      },
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
        paddingBottom: 70,
      },
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-end",
      },
    },
    round: {
      ...commonRoundCSS,
      backgroundColor: "#fff",
      [theme.breakpoints.up("md")]: {
        transition: "all .5s",
        '&:hover': {
          cursor: "pointer",
          backgroundColor: "#fff",
        },
      },
    },
    selectedRound: {
      ...commonRoundCSS,
      backgroundColor: "#fff",
      [theme.breakpoints.up("md")]: {
        backgroundColor: palette.texts.highlight,
      },
    },
    insideRound: {
      position: "absolute",
      left: 1,
      top: 1,
      borderRadius: '100%',
      backgroundColor: palette.texts.dark,
      height: 11,
      width: 11,
      transition: "all .5s",
      '&:hover': {
        cursor: "pointer",
        backgroundColor: "#fff",
      },
    },
    timelineDivider: {
      backgroundColor: palette.texts.light,
      transform: "rotate(90deg)",
      width: 100,
      margin: 50,
      height: 1,
      [theme.breakpoints.down("md")]: {
        marginRight: -35,
      },
      [theme.breakpoints.down("sm")]: {
        marginRight: 50,
      },
      [theme.breakpoints.down("xs")]: {
        marginRight: -35,
      },
    },
    leftProjectContainer: {
      ...commonProjectContainerCSS(theme),
      left: -226,
      [theme.breakpoints.down("md")]: {
        width: 180,
      },
      [theme.breakpoints.down("xs")]: {
        left: -266,
        width: 240,
      },
      [theme.breakpoints.down(360)]: {
        width: 200,
        left: -226,
      },
    },
    rightProjectContainer: {
      ...commonProjectContainerCSS(theme),
      right: -24,
      [theme.breakpoints.down("md")]: {
        right: 'unset',
        left: -226,
        width: 180,
      },
      [theme.breakpoints.down("sm")]: {
        left: 'unset',
        right: -24,
      },
      [theme.breakpoints.down("xs")]: {
        right: 'unset',
        left: -266,
        width: 240,
      },
      [theme.breakpoints.down(360)]: {
        width: 200,
        left: -226,
      },
    },
    dates: {
      color: palette.texts.light,
      fontSize: 13,
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
    projectName: {
      color: "#fff",
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
    projectJobTitle: {
      fontSize: 12,
      color: "#fff",
      [theme.breakpoints.down("md")]: {
        fontSize: 11,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
      },
      [theme.breakpoints.down(360)]: {
        fontSize: 11,
      },
    },
    icon: {
      width: 30,
      height: 30,
      marginRight: theme.spacing(1),
    },
    projectInfoContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
  });

interface Props extends React.PropsWithChildren<WithStyles> {
  updateCurrentProject: (index: number, ref: React.MutableRefObject<null>) => void;
  currentProjectIndex: number;
}

const projectsWithoutSideProjects = projects.filter(project => project.type !== ProjectType.PERSO && project.type !== ProjectType.SCHOOL);
  
const ProjectTimeline: React.FunctionComponent<Props> = (props: Props) => {
  const { classes, updateCurrentProject, currentProjectIndex } = props;
  const { language } = useContext(AppContext);
  const projectsRefs = projects.map(_project => useRef(null));
  const { width } = useWindowDimensions();

  const experienceAnimations = projectsWithoutSideProjects.map((_project, index) => useSpring({
    from: { opacity: 0, y: -30 }, 
    to: { opacity: 1, y: 0 },
    config: { duration: 400 },
    delay: index * 100,
  }));
  
  return (
    <Grid container direction="column" alignItems="center" justify="flex-start" className={classes.root}>
      {projectsWithoutSideProjects.map((project, index) =>
        <React.Fragment key={`${project}-${index}`}>
          <div
            className={`${currentProjectIndex === index ? classes.selectedRound : classes.round}`}
            onClick={() => updateCurrentProject(index, projectsRefs[index])}
            ref={projectsRefs[index]}
          >
            {(currentProjectIndex !== index || width < 960 ) && (
              <div className={classes.insideRound}></div>
            )}
            <animated.div
              className={`${index % 2 === 0 ? classes.leftProjectContainer : classes.rightProjectContainer}`}
              onClick={() => updateCurrentProject(index, projectsRefs[index])}
              style={experienceAnimations[index]}
            >
              <Typography className={classes.dates}>{project.dateStart[language]} - {project.dateEnd[language]}</Typography>
              <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.projectInfoContainer}>
                <img className={classes.icon} src={project.thumbnail} />
                <Grid container item direction="column" style={{ width: "auto" }}>
                  <Typography className={classes.projectName}>{project.name}</Typography>
                  {project.jobTitle && project.jobTitle.map((title) => (
                    <Typography key={`${title}-${index}`} className={classes.projectJobTitle}>{title[language]}</Typography>
                  ))}
                </Grid>
              </Grid>
            </animated.div>
          </div>
          {index + 1 < projectsWithoutSideProjects.length && (
            <Divider className={classes.timelineDivider} />
          )}
        </React.Fragment>
      )}
    </Grid>
  );
};

export default withStyles(styles)(ProjectTimeline);
